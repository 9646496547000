import ColorsHelper from "../../../Helpers/Colors"

const formbuilderStyle = {

  formBorder: { borderRadius: '1rem' },
  formMainCard: {
    background: 'white',
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0px 9px 20px rgba(46, 35, 94, 0.07)',
    marginTop: '1rem',
    marginBottom: "-0.5rem",
    height: "100%",
    overflow: "auto"
  },
  main: { marginTop: '0rem', },
  mainUniId: { marginTop: '5rem', },
  // userlist
  tableHeaderTxt: { fontWeight: "600", fontSize: '0.9rem' },
  addform: { padding: '0.5rem 1rem', margin: '1rem 1rem 1rem 0rem', fontWeight: '600' },
  mainMox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px"
  },
  Btns: { marginight: '3rem', display: "flex", width: "100%" },
  canBtn: {
    width: "7rem",
    height: "2.5rem",
    border: "1px solid " + ColorsHelper.borderBlack,
  },
  subBtn: { padding: "0.5rem 2rem", },
  couponCodeBtn: { padding: "0.3rem 0.5rem", width: "105px" },
  SubmitBtn: { padding: "0.575rem 3.50rem" },
  //formBuilder.tsx

  formHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: '0.2px solid gray',
  },
  iconList: {
    marginRight: "1rem",
    cursor: "pointer"
  },
  headerName: {
    margin: "1rem 1rem",
    fontSize: "1rem"
  },
  HeaderSpan: { fontSize: "25px" },
  noData: {
    height: "36.813rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  input: {
    padding: "0.675rem 0.75rem",

  },
  couponInput: {
    padding: "0.675rem 0.75rem",
    fontWeight: "500",
    fontSize: "1rem",
    color: "rgba(102, 112, 133, 1)",
    marginBottom: "0px",
    fontFamily: "jost-Regular"
  },
  //FormEditFieldDialog
  secName: {
    padding: "0.675rem 0.75rem"
  },
  hr: {
    margin: "15px -15px"
  },
  btnGroupDialog: {
    display: "flex",
    justifyContent: "end"
  },
  btnClose: {
    marginRight: "10px"
  },

  //StepTwoDynamicField
  DropToggal: {
    padding: "12px 12px"
  },
  dropMenu: {
    backgroundColor: "white",
    color: "black",
    overflowY: "auto",
    maxHeight: "10rem",
    scrollbarWidth: "none",
  },
  queReq: {
    marginTop: "0.3rem",
    fontSize: "0.8rem",
  },
  btnMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginRight: "1rem",
    marginTop: '0.5rem'
  },
  addIcon: {
    fontSize: '24px'
  },
  reuLable: {
    marginTop: "5px"
  },
  required: {
    marginBottom: "0px",
  },
  iconGroup: {
    display: "flex",
    alignItems: "center"
  },
  icon1: {
    marginRight: '10px',
    fontSize: '20px',
    cursor: 'pointer'
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "0.5rem",
  },
  Cancle: {
    padding: "0.625rem 2.95rem",
    backgroundColor: "white",
    color: "black",
    border: "0.175rem solid rgb(108, 117, 125)",
    borderRadius: "0.3rem",
  },
  Submit: { padding: "0.575rem 3rem", marginRight: "4px" },
  Previous: {
    padding: "0.575rem 2.75rem",
    marginRight: "0.5rem",
  },
  ButtonDiv: { height: "54px", flexShrink: 0 },
  Icon: { fontSize: "1.5rem" },
  UploadBtn: {
    cursor: "pointer",
    borderRadius: "50%",
    height: "56px",
    width: "56px",
  },
  SubInput: {
    padding: "0.675rem 0.75rem",
  },
  Span: {
    color: "red",
    fontSize: "1.5rem",
    lineHeight: 0,
  },
  Btngroup: { borderColor: "#006666", width: "100px" },
  MobileMain: {
    display: "flex",
    alignItems: "center",
    marginLeft: ".5rem",
    backgroundColor: "#000",
    padding: "2px 6px",
    borderRadius: "5px",
  },
  MdHorizontalSplit: {
    color: "#FFF",
    padding: "0px",
    margin: "0px",
    fontWeight: "600",
    marginLeft: "4px",
  },
  ViewStart: {
    color: "red",
    fontSize: "1.5rem",
    lineHeight: 0,
  },
  validationText: {
    marginTop: "0.3rem",
    fontSize: "0.8rem",
  },
  CustomMobile: {
    height: "70px",
    flexShrink: 0,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
  },
  SecInput: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  subIn: {
    color: "red",
    fontSize: "1.5rem",
    lineHeight: 0,
  },
  Sub: {
    cursor: "pointer",
    fontSize: "0.75rem",
  },

  //Membership Detail
  fullname: {
    fontWeight: "500",
    fontSize: "1rem",
    color: "rgba(102, 112, 133, 1)",
    marginBottom: "0px",
    fontFamily: "jost-Regular"
  },
  username: {
    fontWeight: "500",
    fontSize: "1rem",
    color: "rgba(52, 64, 84, 1)",
    fontFamily: "jost-Regular"

  },
  membershipDe: {
    fontWeight: "500",
    fontSize: "1rem",
    marginBottom: "0px",
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "jost-Regular"
  },
  membershipPlan: {
    fontWeight: "500",
    fontSize: "1rem",
    marginBottom: "0px",
    color: "rgba(52, 64, 84, 1)",
    fontFamily: "jost-Regular"
  },
  imgDiv: {
    width: "55px",
    height: "55px",
    borderRadius: "8px",
    backgroundColor: "#F0F4FF",
    display: "flex"
  },
  displayPrice: {
    marginBottom: "0px", fontWeight: "600", fontFamily: "Jost", fontSize: "15px"
  },
  benifetsDes: {
    display: "flex", alignItems: "start", gap: "0.5rem", marginBottom: "1rem"
  },
}

export default formbuilderStyle 