const ColorsHelper = {
  whiteClr: "#FFFFFF",
  bgWhiteClr: "#FFFFFF",
  blackClr: "#000",
  boxShadowPurple: "#c7c7c7",
  Black: "#333333",
  subBtnClr: " #006666",
  bgPurple: "#7367f0",
  BgBlue: "#3853a3",
  bgBlue1: "#0F1221",
  txtBlack: "#2f2b3d",
  black: "#2f2b3d",
  borderClr: "#006666",
  bgPrime: "#006666",
  txtPrime: "#006666",
  txtGrey: "#ababab",
  txtRed: "#d32f2f",
  txtRed2: "#EE1D23",
  txtgrey: "#ababab",
  txtBlue: "#a8b5cd",
  txtGreen: "#5ABC77",
  boxShadowGrey: "#888888",
  boderGrey: "#d8d8d8",
  boderGrey1: "#d2cfcf",
  boderGrey2: "#cccccc",
  boderGrey3: "#d3d3d3",
  boderGrey4: "#d8d8d8",
  boderGrey5: "#6c757d",
  borderWht: "#E6E9EB",
  borderGreen: "#008000",
  borderWht1: "#dee2e6",
  borderBlack: "#000000",
  borderRed: "#ff775e ",
  borderWhite:"#ecf3fa ",

};

export default ColorsHelper;
