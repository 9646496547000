export const Href = "#javascript";

// Layout
export const SearchISSO = "Search ISSO...";
export const Search = "Search";
export const Bookmark = "Bookmark";
export const Back = "Back";
export const SearchPlaceholder = "search...";
export const LogOut = "Log Out";
export const Notifications = "Notifications";
export const CheckAll = "Check all";
export const OrderTotal = "Order Total";
export const AddNewBookmark = "Add New Bookmark";
export const Pinned = "Pinned";

export const morT = "Good Morning";
export const afternT = "Good Afternooon";
export const evenT = "Good Evening";

// ThemeCustomizer
export const CheckLayouts = "Check layouts";
export const QuickOption = "Quick option";
export const UnlockingCreativity = "Unlocking Creativity";
export const Layout_Type = "Layout Type";
export const Sidebar_Icon = "Sidebar Icon";
export const UnlimitedColor = "Unlimited Color";
export const Mix_Layout = "MixLayout";
export const Sidebar_Type = "Sidebar Type";
export const Box = "Box";
export const Fill = "Fill";
export const LTRBadges = "LTR";
export const RTLBadges = "RTL";
export const Stroke = "Stroke";
export const Configuration = "Configuration";
export const CopyText = "Copy text";
export const LightLayout = "Light layout";
export const DarkLayout = "Dark Layout";

// Dashboard
export const Ecommerce = "E-Commerce";
export const Dashboard = "Dashboard";
export const ProjectStatisticsHeading = "Project Statistics";
export const TodayWorkHeading = "Today Work";
export const ImportantProjectListHeading = "Important Project List";
export const AllProjectTableHeading = "All Project Table";
export const TopClientListHeading = "Top Client List";
export const TimeLineHeading = "Time Line";
export const AddProject = "Add Project";
export const UpgradeNowHeading = "Upgrade Now";
export const Message = "Message";
export const ActivityLogHeading = "Activity Log";
export const Default = "Default";
export const SalesChartHeading = "Sales Chart";
export const CertificateChartHeading = "Certificate Issued by Category";
export const CertificateLevelChartHeading = "Certificate Issued by Levels";
export const CertificateCountryChartHeading = "Certificate by Country";
export const UserCountryChartHeading = "User by Country";
export const GeographicalChartHeading = "Geographical Distribution Map";
export const VerificationChartHeading = "Certificate Verification Status";
export const UserActivityChartHeading = "User Activity";
export const StackedBarChartHeading = "Upcoming Renewal Certificates";
export const PerfomanceChartHeading = "Performance Metrics Radar Chart";
export const Revenue = "Revenue";
export const Orders = "Orders";
export const OnlineStore = "Online Store";
export const LastYear = "Last Year";
export const ViewReport = "View Report";
export const SaleHistoryHeading = "Sale History";
export const ViewAll = "View All";
export const TopRevenueProductHeading = "Top Revenue Product";
export const LiveProductHeading = "Live Product";
export const LatestOrdersHeading = "Latest Orders";
export const OnlineCourse = "Online course";
export const CertiNBadges = "Certificates & Badges";
export const BestSellingProductHeading = "Best Selling Product";
export const LatestTransactionHeading = "Latest Transaction";
export const UserVisitsByDayHeading = "User Visits by Day";
export const TeamActivityHeading = "Team Activity";
export const NewUserHeading = "New User";
export const TopProductHeading = "Top Product";
export const BoostUpYourSaleHeading = "Boost up your sale";
export const UpgradeNow = "Upgrade Now";
export const DeliveriesHeading = "Deliveries";
export const RevenueGrowthHeading = "Revenue Growth";
export const MarketingSale = "Marketing Sale";
export const OnlineSale = "Online Sale";

//Datepicker
export const CalendarDefault = "Default calendar";
export const CalendarBootstrap = "Reactstrap calendar";
export const CustomizingConjunction = "Customizing Conjunction";
export const DefaultDates = "Default Date";
export const DisabledDates = "Disabled Dates";
export const HumanFriendly = "Human Friendly";
export const MinMaxValue = "Min-Max Value";
export const MultiplesDates = "Multiples Dates";
export const Range = "Range";
export const TimePickerTitle = "Time picker";
export const TimePickers = "Time Picker";
export const DatePicker = "Date Picker";
export const MultiplesDatesHeading = "Multiples Dates";
export const RangeDatePicker = "Range";
export const PreloadingDates = "Preloading Dates";
export const TimPickers = "Time picker";
export const TimePickerHeading = "Time Picker";
export const HourPicker = "24-hour Time Picker";
export const TimePickerLimits = "Time Picker W/Limits";
export const PreloadingTime = "Preloading Time";
export const TimePickerRange = "TimePicker with Limited Time Range";
export const TimePickerMinMaxRange = "TimePicker with Min/Max Time Range";
export const DateWithTime = "Date With Time";
export const DateAndTimeHeading = "Date and time";
export const Month = "Month";
export const Week = "Week";
export const Time = "Time";

//Touchspin
export const Touchspin = "Touchspin";
export const DefaultTouchspins = "Default touchspin";
export const OutlinedTouchspins = "Outlined touchspin";
export const IconsWithPrefixAndPostfixs = "Icons with prefix and postfix";
export const ButtonsWithPrefixAndPostfixs = "Buttons with prefix and postfix";
export const PreText = "Pre";
export const PostText = "Post";
export const RoundedTouchspin = "Rounded touchspin";

//Mega options
export const MegaOptions = "Mega Options";
export const VariationRadios = "Variation radio";
export const VariationCheckboxHeading = "Variation checkbox";
export const ChooseActivities =
  "Which of the following activities do you enjoy doing in your free time?";
export const UpgradeTitle = "It's time to";
export const UpgradeSpanText = "upgrade";
export const DefaultStyleMegaOptions = "Default style";
export const WithoutBordersStyles = "Without borders style";
export const SolidBorderStyles = "Solid border style";
export const OfferStyleBorders = "Offer style border";
export const InlineStyles = "Inline style";
export const VerticalStyles = "Vertical style";
export const HorizontalStyles = "Horizontal style";

//Input groups
export const FormControls = "Form Controls";
export const InputGroup = "Input groups";
export const ButtonAddon = "Button addons";
export const SubmitButtonAddon = "Submit";
export const SubmitButtonAddonPlaceHolder = "Recipient's username";
export const AddonPlaceHolder = "Recipient's username";
export const CustomForm = "Custom forms ";
export const OptionsFormGroup = "Options";
export const PixelstrapThemeTitle = "Select Your Favorite Pixelstrap theme";
export const FavoriteColorsTitle = "Select Your Favorite Colors";
export const FavoriteChocolatesTitle = "Select Your Favorite Chocolates";
export const FavoriteThemeTitle = "Select Your Favorite Theme";
export const CustomFormSubmit = "Submit";
export const CustomFileInputs = "Custom file input";
export const CustomFileInputsUpload = "Upload";
export const CustomFileInputsVerify = "Verify";
export const CustomFileInputsSubmit = "Submit";
export const ButtonsWithDropdown = "Buttons with dropdowns";
export const ButtonsWithDropdownTitle = "Dropdown";
export const ToggleDropdown = "Toggle Dropdown";
export const SegmentedButton = "Segmented buttons";
export const SegmentedButtonInfo = "Info";
export const SegmentedButtonSecondary = "Secondary";
export const CheckboxesAndRadio = "Checkboxes and radios";
export const Sizings = "Sizing";
export const SmallInput = "Small";
export const DefaultInput = "Default";
export const LargeInput = "Large";
export const MultipleInput = "Multiple inputs";
export const FirstAndLastName = "First and last name";
export const BasicInputGroup = "Basic input groups";
export const BasicInputGroupBasic = "Basic";
export const YourVanityUrl = "Your vanity URL";
export const WithTextarea = "With textarea";
export const UsernamePlaceholder = "Username";
export const RecipientPlaceholder = "Recipient's username";
export const ServerPlaceholder = "Server";
export const Wrapping = "Wrapping";
export const ButtonBasicInputSubmit = "Submit";
export const ButtonBasicInputCancel = "Cancel";
export const VariationOfAddon = "Variation of addons";
export const LeftAddon = "Left Addon";
export const RightAddon = "Right Addon";
export const JointAddon = "Joint Addon";
export const LeftAddonPlaceholder = "Email";
export const RightAddonPlaceholder = "Recipient's username";
export const LeftAndRightAddon = "Left & Right Addon";
export const SolidStyle = "Solid style";
export const FlatStyle = "Flat style";
export const RaiseStyle = "Raise style";
export const LeftAndRight = "Left & Right Addon";
export const VariationOfAddonsSubmit = "Submit";
export const VariationOfAddonsCancel = "Cancel";

//Input Mask
export const InputMasks = `Input masks`;
export const DefaultInputMask = "Default Input Mask";
export const DateFormatHeading = "Date Format";
export const DateFormatType = "Date format type";
export const TimeFormatHeading = "Time Format";
export const TimeFormatType = "Time format type";
export const HourMonthType = "Hour/month type";
export const CurrencyPlaceholder = "hh:mm";
export const Prefix = "Prefix";
export const PrefixPlaceholder = "Prefix-xxxx-xxxx-xxxx";
export const Delimiter = "Delimiter";
export const DelimiterPlaceholder = "xxx·xxx·xxx";
export const PhoneNumber = "Phone Number";
export const PhoneNumberPlaceholder = "(xxx)xxx-xxxx";
export const CardNumberPlaceholder = "xxxx xxxx xxxx xxxx";
export const Tailprefix = "Tail prefix";
export const TailprefixPlaceholder = "0000.00€";

//Checkbox & Radio
export const FormsControls = "Forms Controls";
export const CheckboxAndRadio = "Checkbox & Radio";
export const DefaultCheckboxHeading = "Default checkbox";
export const DefaultCheck = "Default Checks";
export const DefaultCheckBox = "Default checkbox";
export const CheckedCheckbox = "Checked checkbox";
export const DisabledChecks = "Disabled Checks";
export const IndeterminateHeading = "Indeterminate";
export const IndeterminateCheckbox = "Indeterminate checkbox";
export const CustomCheckboxHeading = "Custom checkbox";
export const BorderedCheckboxHeading = "Bordered Checkbox ";
export const PrimaryCheckboxPrimary = "Primary - checkbox-primary";
export const IconCheckboxHeading = "Icon Checkbox ";
export const FilledCheckboxHeading = "Filled Checkbox";
export const WarningCheckboxSolidWarning = "Warning - checkbox-solid-warning ";
export const DefaultRadios = "Default radio";
export const CustomRadio = "Custom Radios";
export const DefaultRadioLabel = "Default radio";
export const DefaultCheckedRadio = "Default checked radio";
export const ImagesWithCheckboxs = "Images with checkbox";
export const ImagesWitRadio = "Images with radio";
export const Custom = "Custom";
export const CustomRadios = "Custom radio";
export const BorderedRadios = "Bordered Radio";
export const KohoTheme = "Koho Theme";
export const IconsRadios = "Icons Radio";
export const FilledRadios = "Filled Radio";
export const ProductRadio = "Product";
export const DefaultSwitch = "Default switches";
export const CustomSwitches = "Custom Switches";
export const DefaultSwitchCheckboxInput = "Default switch checkbox input";
export const CheckedSwitchCheckboxInput = "Checked switch checkbox input";
export const InlineInputType = "Inline input-types";
export const InlineCheckboxHeading = "Inline Checkbox";
export const InlineRadio = "Inline Radios";
export const InlineSwitches = "Inline Switches";
export const AnimatedButton = "Animated buttons";
export const SelectYourPaymentMethods = "Select your payment method";
export const VisaAnimated = "Visa";
export const InstagramAnimated = "Instagram";
export const WhatIsYourFavoriteSocialMedia =
  "What is your favorite social media?";
export const BasicRadioAndCheckboxHeading = "Basic radio and checkbox";
export const SimpleCheckboxHeading = "Simple Checkbox";
export const BasicRadioAndCheckboxBlog = "Blog";
export const BasicRadioAndCheckboxSimpleRadios = "Simple Radios";
export const BasicRadioAndCheckboxMaps = "Maps";
export const RadioToggleButton = "Radio toggle buttons";
export const RadioChecked = "Checked";
export const OutlinedCheckboxStyle = "Outlined checkbox styles";
export const SingleToggle = "Single toggle";
export const ButtonChecked = "Checked";
export const ButtonCheckedSuccessRadio = "Checked success radio";
export const ButtonDarkRadio = "Dark radio";
//Forms Validation
export const FormsControl = "Forms Control";
export const ValidationForms = "Validation Forms";
export const FormValidationTooltip = "Tooltip form validation";
export const LooksGood = "Looks good!";
export const UserName = "User Name";
export const UserNameReq = "User name is required";
export const UsernameFeedback = "Please choose a unique and valid username.";
export const Zip = "Zip";
export const StateChoose = "Choose...";
export const CityFeedback = "Please provide a valid city.";
export const StateFeedback = "Please select a valid state.";
export const ZipFeedback = "Please provide a valid zip.";
export const SubmitButton = "Submit form";
export const MarkFirstNamePlaceholder = "Mark";
export const OttolastNamePlaceholder = "Otto";
export const BrowserDefaults = "Browser defaults";
export const FirstNamePlaceholder = "First name";
export const PasswordBrowserDefault = "Password";
export const ChooseFile = "Choose file";
export const SelectYourPaymentMethod = "Select your payment method";
export const Visa = "Visa";
export const MasterCard = "MasterCard";
export const PayMethodTitle = "Select your payment method";
export const AgreeToThePolicies = "I agree to the policies";
export const SureInformation = "Are you sure above information are true";
export const ValidationsForms = "Validation form";
export const FirstNameError = "Please enter your valid firstname";
export const FirstNameFeedback = "Please enter your valid";
export const PasswordValidation = "Password";
export const PasswordFeedback = "Please enter your valid password";
export const StateInvalidFeedback = "Please select a valid state.";
export const CityInvalidFeedback = "Please provide a valid city..";
export const ZipInvalidFeedback = "Please provide a valid zip.";
export const VISA = "VISA";
export const SelectThemeTitle = "Select Your Favorite Pixelstrap theme";
export const ThemeError = "Invalid select feedback";
export const ChooseFiles = "Choose File";
export const InvalidFormFileSelected = "Invalid form file selected";
export const TextAreaPlaceHolder = "Enter your comment";
export const TextAreaError = "Please enter a message in the textarea.";
export const TermsError = "You must agree before submitting.";
export const AgreeCondition = "Agree to terms and conditions";
export const AgreeSubmitting = "You must agree before submitting.";

//Base Input
export const BaseInputs = "Base inputs";
export const FormBasics = "Basic form";
export const EmailLabel = "Email";
export const EmailPlaceHolder = "Enter Your Email";
export const PasswordLabel = "Password";
export const PasswordPlaceholder = "Enter Your Password";
export const SignInButton = "Sign in";
export const FormFloatingHeading = "Floating form";
export const EmailFloatingPlaceholder = "name@example.com";
export const PasswordFloatingPlaceholder = "Password";
export const FloatingPasswordPlaceholder = "Password";
export const SelectSizings = "Select sizing";
export const WhatsYourHobbies = "What's Your Hobbies";
export const FormControlSizings = "Form control sizing";
export const FormControlSizingsPlaceholder = ".form-control-sm";
export const FileInputs = "File input";
export const FlatInputStyles = "Flat input style";
export const SmallFileInputExample = "Small file input example";
export const LargeFileInputExample = "Large file input example";
export const SelectYourFavoriteRomanNumber =
  "Select your favorite roman number";
export const SelectYourMultiplePaintings = "Select your multiple paintings";
export const PleaseDoComments = "Please do comments";
export const BasicHtmlInputControls = "Basic HTML input control";
export const BasicPlaceholder = "Placeholder";
export const BasicPlaceholderPlace = "Type your title in Placeholder";
export const BasicPassword = "Password";
export const PasswordInputPlaceholder = "Password input";
export const BasicNumber = "Number";
export const NumberPlaceholder = "Number";
export const BasicTelephone = "Telephone";
export const BasicTelephonePlacholder = "91-(999)-999-999";
export const URLs = "URL";
export const URLsValue = "https://getbootstrap.com";
export const BasicDateAndTime = "Date and time";
export const FullDateAndTime = "2018-01-19T18:45:00";
export const BasicDate = "Date";
export const FullDate = "2018-01-01";
export const BasicMonth = "Month";
export const FullMonth = "2018-01";
export const BasicWeek = "Week";
export const FullWeek = "BasicWeek";
export const BasicDatalistExample = "Datalist example";
export const ListPlaceholder = "Look up your nation...";
export const BasicTime = "Time";
export const BasicColorPicker = "Color picker";
export const BasicMaximumLength = "Maximum Length";
export const MaximumLengthPlaceholder = "Content must be in 6 characters";
export const BasicStaticText = "Static Text";
export const StaticTextData = "Hello !... This is my magical text";
export const BasicSubmitButton = "Submit";
export const BasicCancleButton = "Cancle";
export const BasicFloatingInputControls = "Basic floating input control";
export const FloatingValidInput = "Valid input ";
export const InputWithValue = "Input with value";
export const InvalidInput = "Invalid input";
export const FloatingComments = "Comments";
export const FloatingEmail = "Email";
export const FloatingPassword = "Password";
export const FloatingComment = "Comments";
export const FloatingCommentPlaceholder = "Leave a comment here";
export const FloatingCommentLabel = "Comments";
export const InputWithValuePlaceholder = "name@example.com";
export const InvalidInputPlaceholder = "name@example.com";
export const LayOutPlaceholder = "name@example.com";
export const LayOutDefaultvalue = "mdo@example.com";
export const CommentsPlaceholder = "Comments";
export const InputWithValuePlaceholderValue = "test@example.com";
export const InvalidInputPlaceholderValue = "test@example.com";
export const FloatingEmailLabel = "Email address";
export const FloatingOpenLabel = "Open this select menu";
export const FloatOpenFloatingLabel = "Works with selects";
export const FloatingInputGroup = "Input group";
export const FloatingInputGroupLabel = "Username";
export const FloatingInputGroupFeed = "Please choose a username.";
export const FloatingLayout = "Layout";
export const FloatingCancleButton = "Cancle";
export const FloatingSubmittButton = "Submit";
export const EdgesInputStyles = "Edges input style";
export const EdgeEmailAddress = "Email address";
export const EdgeEmailAddressPlaceholder = "name@example.com";
export const EdgePassword = "Password";
export const EdgePasswordPlaceholder = "Password";
export const SelectYourFavoritePixelstrapTheme =
  "Select your favorite pixelstrap theme";
export const DefaultFileInputExamples = "Default file input example";
export const MultipleFilesInputExample = "Multiple files input example";
export const DisabledFileInputExample = "Disabled file input example";
export const EdgeDefaultInput = "Default Input";
export const EdgeDefaultInputPlaceholder = "Default Input";
export const EdgeMale = "Male";
export const EdgeFemale = "Female";
export const EdgeComments = "Comments";
export const EdgeSubmit = "Submit";
export const EdgeCancel = "Cancel";
export const RaiseInputStyles = "Raise input style";
export const RaisedEmailAddress = "Email address";
export const RaisedEmailAddressPlaceholder = "name@example.com";
export const RaisedPassword = "Password";
export const RaisedPasswordPlaceholder = "Password";
export const RaisedSubmit = "Submit";
export const RaisedCancel = "Cancel";
export const RaisedSelectYourFavoritePixelstrapTheme =
  "Select your favorite pixelstrap theme";
export const SelectTheColorYouLikeBelow = "Select the color you like below";
export const RaisedComments = "Comments";

//Maps
export const GoogleMap = "Google Map";
export const BasicDemoMap = "Basic Demo";
export const CurrentLocation = "Current Location";
export const MarkerMap = "Marker Map";
export const PolygonsTitle = "POLYGONS";
export const Polygons = "POLYGONS";
export const LeafletMap = "Leaflet Map";
export const LeafletWorldMap = "Leaflet World Map";
export const LeafletUSAMap = "Leaflet USA Map";
export const LeafletIndiaMap = "Leaflet India Map";
export const LeafletAustraliaMap = "Leaflet Australia Map";

//Editors
export const Editors = "Editors";
export const AceEditor = "Ace Editor";
export const CkEditor = "Ck Editor";
export const CKEditorExample = "CK Editor";
export const EditorsText = "Your title";
export const InlineEditors = "Inline Editor";
export const MDEEditor = "MDE Editor";
export const MdeEditorExample = "MDE Editor Example";
export const SecondExample = "Second Example";
export const Instructions = "Instructions";
export const ACECodeEditor = "ACE Code Editor";
export const JavascriptModeTitle = "Javascript Mode";
export const HTMLModeHeading = "HTML Mode";
export const CSSModeHeading = "CSS Mode";
export const JavaModeHeading = "Java Mode";
//Learnings
export const LearningFilter = "Learning filter";
export const Learning = "Learning";
export const LearningList = "Learning List";
export const FindCourseLearning = "Find Course";
export const FilterLearning = "Filter";
export const SearchLearningPlaceholder = "Search..";
export const CategoriesLearning = "Categories";
export const CategoriesTitleLearning = "Categories";
export const DurationLearning = "Duration";
export const PriceLearning = "Price";
export const StatusLearning = "Status";
export const RegistrationLearning = "Registration";
export const DevelopmentTitle = "Development";
export const DesignLearning = "Design";
export const UpcomingCoursesHeading = "Upcoming Courses";
export const CourseBy = "Course By";
export const DetailedCourse = "Detailed Course";
export const CommentsLearning = "Comments";
export const DateLearning = "25 July 2024";
export const CommentTitleLearning = "Comment";

//Job Search
export const JobSearch = "Job Search";
export const CardsView = "Cards View";
export const JobApply = "Job Apply";
export const JobFilterData = "Job filter";
export const FilterHeading = "Filter";
export const FindJobs = "Find jobs";
export const LocationHeading = "Location";
export const AllLocations = "All Locations";
export const JobHeading = "Job Title";
export const AllJobTitle = "All Job Title";
export const IndustryHeading = "Industry";
export const AllIndustries = "All Industries";
export const ListView = "List View";
export const JobDetails = "Job Detail";
export const ApplyForThisJob = "Apply";
export const ProductDesignerMainCard = "Product Designer (UI/UX Designer)";
export const EndLessDesigner = "Endless - United States";
export const SimilarJobs = "Similar jobs";
export const JobDescriptionHeading = "Job Description";
export const Qualifications = "Qualifications";
export const AgencyExperience = "Agency experience";
export const Perks = "Perks";
export const Share = "share";
export const JobUIDesigner = "UI Designer";
export const JobEndlessTelecomTechnologie =
  "Endless Telecom & Technologies , NY";
export const JobPersonalDetails = "Personal Details";
export const JobYourEducation = "Your Education";
export const JobYourExperience = "Your Experience";
export const JobUploadYourFiles = "Upload Your Files";
export const JobSubmitButton = "Submit";
export const JobCancelButton = "Cancel";
export const FullNameJob = "Full name";
export const FullNameJobPlaceholder = "Enter your full name";
export const EmailJob = "Email";
export const EmailJobPlaceholder = "Email";
export const PasswordJob = "Password";
export const PasswordJobPlaceholder = "Enter password";
export const RepeatPasswordJob = "Repeat Password";
export const RepeatPasswordJobPlaceholder = "Repeat password";
export const PhoneNumberJob = "Phone Number";
export const PhoneNumberJobPlaceholder = "Enter Phone no.";
export const CollegeNameJob = "College Name";
export const PeriodJob = "Period";
export const DegreeLevelJob = "Degree Level";
export const SpecializationJob = "Specialization";
export const LocationJob = "Location";
export const PositionJob = "Position";
export const PositionJobPlaceholder = "Enter Position";
export const CompanyNameJob = "Company Name";
export const UploadCoverLetterJob = "Upload Cover Letter";
export const UploadYourCvJob = "Upload Your CV";
export const UploadRecommendationsJob = "Upload Recommendations";
export const BirthDateJob = "Birth Date";
export const CollagePlaceholder = "Enter college name";
export const DegreePlaceholder = "Degree";
export const SpecializationPlaceholder = "Enter specialization";
export const LocationJobPlaceholder = "Enter Location";
export const CompanyJobPlaceholder = "Enter Company Name";
export const SaveThisJob = " Save this job";
export const SpecificSkills = "Specific skills";
export const AllSkills = "All Skills";
export const LocationPlaceholder = "location..";

//FAQ
export const Faq = "FAQ";
export const QuickQuestions = "Quick Questions are answered";
export const FeaturedTutorials = "Featured Tutorials";
export const LatestArticles = "Latest articles and videos";
export const SearchArticles = "Search Articles";
export const SearchArticlesPlaceholder = "Search..";
export const Navigation = "Navigation";
export const AskQuestion = "Ask Question";
export const AskOurCommunity = "Ask our community";
export const FaqContactUs = "Contact Us";
export const FaqLatestUpdates = "Latest Updates";

//Support Ticket
export const SupportTicket = "Support Ticket";
export const SupportTicketList = "Support Ticket List";
export const ProfitSupportTicket = "Profit";
export const LossSupportTicket = "Loss";
export const DeleteSupportTicket = "Delete";
export const DeleteData = "Delete Selected Data..!";

//Knowledgebase
export const Knowledgebase = "Knowledgebase";
export const KnowledgebaseHelpQue = "How Can I help you?";
export const KnowledgebasePlaceholder = "Type question here";
export const KnowledgeArticle = "Browse articles by category";
export const BrowseArticles = "Browse Articles";
export const FeaturedTutorial = "Featured Tutorials";
//Blog
export const BlogDetails = "Blog Details";
export const Hits = "Hits";
export const SingleBlogHeading = `The Harpeth rises in the westernmost part of Rutherford County, just to the east of the community of College Grove in eastern Williamson County.`;
export const BlogSingle = `Blog Single`;
export const AddBlogs = "Add Blogs";
export const AddPost = "Add Post";
export const PostEdit = "Post Edit";
export const PostTitle = "Title";
export const PostTitlePlaceholder = "Post Title";
export const PostCategory = "Category";
export const PostType = "Type";
export const PostContent = "Content";
export const PostTypePlaceholder = "Select Your Name....";
export const BlogPostText = `Enter text in the area on the left. For more info, click the ? (help) icon in the menu.`;
export const BlogPostButton = "Post";
export const BlogDiscardButton = "Discard";
export const BlogDropFilesHereOrClickToUpload =
  "Drop files here or click to upload.";
export const blogDelTxt = "This blog will be deleted permanently.";
export const blogTypeDelTxt = "This blog type will be deleted permanently.";
export const BlogType = "Blog Type";
export const SelCreBy = "Select Created by";
export const SelBlogType = "Select Blog Type";
export const Approved = "Approved";
export const ThisIsAppr = "This Is Approved";
export const ThisIsRej = "This Is Rejected";
export const BlogsTitle = "Blogs";
export const BlogInside = "Blog Inside";
export const BlogContent = "Blog Content";
export const Blogimages = "Blog Images";

//Miscellaneous - Gallery
export const Gallery = "Gallery";
export const ImageGallery = "IMAGE GALLERY";
export const MasonryGallery = "Masonry Gallery";
export const GalleryGridWithDescriptions = "Gallery Grid With Description";
export const GalleryGridWithDescriptionCap = "IMAGE GALLERY WITH DESCRIPTION";
export const MasonryGalleryWithDescription = "Masonry Gallery With Description";
export const MasonryGalleryCap = "MASONRY GALLERY";
export const MasonryGalleryDescriptionCap = "MASONRY GALLERY WITH DESCRIPTIONY";
export const PortfolioTitle = "Portfolio Title";
export const ImageHoverEffects = "Image Hover Effects";

//Buttons
export const DefaultStyles = "Default Style";
export const Buttons = "Button";
export const DefaultButtonsHeading = "Default Buttons";
export const LargeButtonsHeading = "Large Buttons";
export const SmallButtonsHeading = "Small Buttons";
export const ExtraSmallButtonsHeading = "Extra Small Buttons";
export const ActiveButtonsHeading = "Active Buttons";
export const DisabledButtonsHeading = "Disabled Buttons";
export const CustomStateButtonsHeading = "Custom State Buttons";
export const OutlineButtonsHeading = "Outline Buttons";
export const BoldBorderOutlineButtonsHeading = "Bold Border Outline Buttons";
export const OutlineLargeButtonsHeading = "Outline Large Buttons";
export const OutlineSmallButtonsHeading = "Outline Small Buttons";
export const OutlineExtraSmallButtonsHeading = "Outline Extra Small Buttons";
export const DisabledOutlineButtonsHeading = "Disabled Outline Buttons";
export const GradientButtonsHeading = "Gradient Buttons";
export const Links = "Link";
export const CustomButtons = "Custom state buttons";
export const ButtonHeading = "Button";
export const NestingButtonSpan = `Make nesting buttons`;
export const FlatButton = "Flat Buttons";
export const EdgeButton = "Edge Buttons";
export const RaisedButton = "Raised Buttons";
export const ButtonGroups = "Button Group";
export const RadioButtonGroup = "Radio Button Group";
export const CheckBoxButtonGroup = "CheckBox Button Group";
export const Option1 = "Option 1";
export const Option2 = "Option 2";
export const Action = "Action";
export const AnotherAction = "Another Action";
export const Header = "Header";
export const Vertical = "Vertical";
export const BasiButtonGroups = "Basic button group";
export const Left = "Left";
export const Middle = "Middle";
export const Right = "Right";
export const EdgesButtonGroups = "Edges button group";
export const FlatButtonGroups = "Flat button group";
export const LargeButtonGroups = "Large Button group";
export const LargeEdgesButtonGroups = "Large Edges Button group";
export const OutlineCustomButtonGroupHeading = "Outline Custom button group";
export const OutlineButtonGroups = "Outline button group";
export const OutlineEdgeButton = "Outline Edges button";
export const OutlineFlatButtons = "Outline flat button";
export const RadioButtonGroups = "Radio button group";
export const CheckBoxButtonGroups = "Check button group";
export const NestingButton = "Nesting";
export const DropdownLink = "Dropdown link";
export const VerticalGroupButton = "Vertical";

//Icon
export const Icons = "Icons";
export const FlagIcon = "Flag Icons";
export const FontAwesomeIcons = "Font Awesome Icon";
export const Currency = "Currency";
export const WebApp = "Web App";
export const Spinner = "Spinner";
export const FormControl = "Form Control";
export const TextEditor = "Text Editor";
export const Directional = "Directional";
export const VideoPlayer = "Video Player";
export const BrandPlayer = "Brand Player";
export const Medical = "Medical";
export const IcoIcon = "ICO Icon";
export const Abstract = "Abstract";
export const Sport = "Sport";
export const Social = "Social";
export const Weather = "Weather";
export const WebApplication = "Web Application";
export const Travel = "Travel";
export const Transport = "Transport";
export const Multimedia = "Multi media";
export const MobileUI = "Mobile UI";
export const Mathematical = "Mathematical";
export const Animal = "Animal";
export const Construction = "Construction";
export const Device = "Device";
export const Education = "Education";
export const FileType = "File Type";
export const Food = "Food";
export const Law = "Law";
export const Emoticon = "Emoticon";
export const KidsToys = "Kids & Toys";
export const Payment = "Payment";
export const Person = "Person";
export const ThemifyIcon = "Themify Icon";
export const ArrowsDirection = "Arrows & Direction ";
export const Control = "Control";
export const FeatherIcons = "Feather Icons";
export const WhetherIcon = "Whether Icon";
export const WhetherIconsWithAnimations = "Whether Icons With Animations";
export const Markup = "Markup";

//Bonus Ui - Timeline
export const ConferenceWithClient = "CONFERENCE WITH CLIENT";
export const HoveringTimelines = "Hovering Timeline";
export const BasicTimelines = "Basic Timeline";
export const Conference = "Conference";
export const MeetUp = "Meet-up";
export const MeetUpTimelineTitle = "Meet-up";
export const TimelineTitle = "Timeline";
export const Location = "Location";
export const LocationTitle = "LocationTitle";
export const AppIdeas = "app-ideas";
export const EstablishedNewTheAppIdeaRepository =
  "Established new the app-ideas repository.";
export const ViewItOnGithub = "View it on Github ";
export const BlogTimelineTitle = "Blog";
export const VariationTimelines = "Variation Timeline";
export const UpdatedProduct = "Updated Product";
export const ReadMore = "Read More";
export const HorizontalTimelines = "Horizontal Timeline";
export const ImplementedTheProgramForWeeklyCodeChallenges =
  "Implemented the program for weekly code challenges.";
export const AudioTesting = "Audio testing";
export const MusicalTrendsAndPredictability =
  "Musical trends and predictability";
export const WebDesignersMeeUp = "Web-designer's meet-up";
export const PleaseMeetUp = "Please! Meet-up";
export const MyResolutions = "My Resolutions";
export const MyResolutionsFor2024 = "My Resolutions for 2024";
export const USMeeting = "US Meeting";
export const Resolutions = "Resolutions";
export const CarouselText = "Research about most recent design trends.";
//Bonus Ui - Basic Card
export const BasicCardHeading = "Basic Card";
export const FlatCards = "Flat Card";
export const WithoutShadowCards = "Without shadow Card";
export const ISSOTheme = "ISSOTheme";
export const IconInHeadings = "Icon In Heading";
export const DarkColorCards = "Dark Color Card";
export const FooterCard = "Card Footer";
export const SmartHeadphones = "Smart headphones";

//Bonus Ui - Creative Card
export const CreativeCard = "Creative Card";
export const BorderLefts = "Border Left";
export const BorderRights = "Border Right";
export const TopBorder = "Border Top";
export const BottomBorder = "Border Bottom";
export const TeresaMosteller = "Teresa J. Mosteller";
export const GloriaAcheson = "Gloria D. Acheson";
export const BryanOwens = "Bryan A. Owens";
export const PrimaryBorderState = "Border Primary State";
export const WarningBorderState = "Border Warning State";
export const SecondaryBorderState = "Border Secondary State";
export const EmailAddressPlaceHolders = "youremail@gmail.com";
export const Timeline = "Timeline";
export const AbsoluteCards = "Absolute Card";

//Bonus Ui - Range Slider
export const RangeSlider = "Range Slider";
export const DefaultRangeSliders = "Default Range Slider";
export const MinMaxValues = "Min Max Value";
export const NegativeValues = "Negative Value";
export const Disable = "Disabled";
export const Prefixs = "Prefix";
export const FormatedLabels = "Formated Label";

//Bonus Ui - Image-Cropper
export const ImageCropper = "Image Cropper";
export const HiddenDownload = "Hidden download";
export const DownloadCrop = "Download Crop";
//Bonus Ui - OwlCarousel
export const OwlCarousel = "Owl Carousel";
export const SlideOnly = "Slides Only";
export const WithControl = "With Controls";
export const AutoPlayVariants = "Auto Play Variant";
export const MouseWheelVariants = "Mouse Wheel Variant";
export const WithIndicator = "With Indicators";
export const WithCaption = "With Captions";
export const CrossFades = "Cross-Fade";
export const IndividualCarouselItemIntervals =
  "Individual Carousel-Item Interval";
export const DisableTouchSwipings = "Disable Touch Swiping";
export const DarkVariants = "Dark Variant";

//Bonus Ui - Ribbons
export const Ribbons = "Ribbons";
export const VariationsOfLeftRibbons = "Variations Of Left Ribbons";
export const VariationsOfRightRibbons = "Variations Of Right Ribbons";
export const RibbonLeftText2 = "to identify your items.";
export const CssRibbons = "CSS ribbons ";
//Bonus Ui - Sweet Alerts
export const SweetAlert = "Sweet Alert";
export const BasicExamples = "Basic Example";
export const ClickIt = "Click it!";
export const Surprise = "Surprise!";
export const TitleWithATextUnder = "Title With A Text Under";
export const InfoAlerts = "Info Alert";
export const Informational = "Informational";
export const WarningAlerts = "Warning Alert ";
export const SweetWarningButton = "Warning";
export const PikachuAlerts = "Pikachu Alert";
export const Pikachu = "Pikachu";
export const QuestionsAlerts = "Questions Alert ";
export const QuestionsState = "Questions state";
export const UsernameAlerts = "Username Alert";
export const SuccessMessages = "Success Message";
export const LoginSuccessfully = "Login successfully";
export const PassWordMust = "Password must be required";
export const DangerAlerts = "Danger Alert ";
export const DangerMode = "Danger mode";
export const WarningModes = "Warning Mode";
export const AlertMode = "Alert mode";
export const Timer = "Timer";
export const AjaxRequestMovies = "AJAX Request Movie";
export const MovieMode = "Movie mode";

//Pagination
export const Pagination = "Pagination";
export const DefaultPaginations = "Default Pagination";
export const PaginationWithActiveAndDisabled =
  "Pagination With Active And Disabled";
export const PaginationWithIcon = "Pagination With Icons";
export const RoundedPaginations = "Rounded Pagination";
export const PaginationAlignments = "Pagination Alignment";
export const PaginationSizings = "Pagination Sizing";

//Bonus Ui - Dropzone
export const Dropzone = "Dropzone";
export const DefaultFileUploads = "Default File Upload";
export const PreviewImage = "Image Preview ";
export const SingleFileUploads = "Single File Upload";
export const MultiFileUploads = "Multi File Upload";

//Bonus Ui - Tour
export const Tour = "Tour";
export const WilliamJennings = "William C. Jennings";
export const WebDesignerTour = " Web Designer";
export const FollowerTour = "Follower";
export const FollowingTour = "Following";
export const WonderFullTour =
  "Wonderful piece that successfully conveys the magnificence of the mountains and the natural world.";
export const ReadMoreTour = "ReadMore";
export const PainterTourTitle =
  "English Romantic painter, printer, and watercolourist ";
//Bonus Ui - Toasts
export const Toasts = "Toasts";
export const LiveToasts = "Live Toast";
export const TopRightToasts = "Top-right toast";
export const BottomRightToasts = "Bottom-right toast";
export const TopLeftToasts = "Top-left toast";
export const BottomLeftToasts = "Bottom-left toast";
export const YourAccountWillBePermanentlyDeleted =
  "Your account will be permanently deleted?";
export const ImNotSure = "I'm not sure";
export const RemoveMyAccount = "Remove My Account";
export const ColorsScheme = "Colors Schemes";
export const StackingToast = "Stacking Toasts";
export const TranslucentToast = "Translucent Toasts";
export const DefaultToasts = "Default Toast";
export const UniqueToasts = "Unique Toast";
export const ToastPlacements = "Toast Placement";

//Bonus Ui - Rating
export const RatingBars = "1 to 10 Rating Bar";
export const MovieRatingBar = "Movie Rating Bar";
export const SquareRatingBar = "Square Rating Bar";
export const PillRatingBar = "Pill Rating Bar";
export const ReversedRatingBar = "Reversed Rating Bar";
export const StarRatingBar = "Star Rating Bar";
export const HalfStarRatingBar = "Half Star Rating Bar";
export const ThumbUpDownRate = "Thumb Up/Down Rating Bar";
export const HeartRatingBar = "Heart Rating Bar";
export const Rating = "Rating";
//Bonus Ui - Scrollable
export const BonusUi = "Bonus Ui";
export const Scrollable = "Scrollable";
export const CustomScrollbars = "Custom Scrollbar";
export const SmallSizeScrollbars = "Small Size Scrollbar";
export const SolutionBusinessTransformation =
  "Solution for Business Transformation";
export const BadgesScrollbars = "Badges Scrollbar";
export const ProfileScrollables = "Profile Scrollable";
export const ScrollableContents = "Scrollable Content";
export const HorizontalScrollbars = "Horizontal Scrollbar ";
export const BothSideVisibleScrollbars = "Both Side Visible Scrollbar";

//Bonus Ui - Tree View
export const TreeView = "Tree View";
export const BasicTree = "Basic View";
export const DisabledTrees = "Disabled Tree";

//Breadcrumb
export const BreadcrumbHeading = "Breadcrumb";
export const BreadcrumbHome = "Home";
export const BreadcrumbUiKits = "Ui Kits";
export const BreadcrumbAlert = "Alert";
export const BreadcrumbProgress = "Progress";
export const BreadcrumbBonusUi = "Bonus Ui";
export const BreadcrumbTable = "Table";
export const BreadcrumbBootstrapTable = "Bootstrap Tables";
export const DefaultBreadcrumbs = "Default Breadcrumb";
export const DividerBreadcrumbs = "Divider Breadcrumb";
export const WithIconsBreadcrumbs = "With Icons Breadcrumb";
export const VariationOfBreadcrumbs = "Variation Of Breadcrumb";
export const ColoredBreadcrumbs = "Colored Breadcrumb";
//Ui Kits Tabs
export const ReactstrapTabs = "Reactstrap Tabs";
export const Tabs = "Tabs";
export const SimpleTab = "Simple tabs";
export const IconsWithTab = "Icons With tabs";
export const VerticalTab = "Vertical tabs";
export const PillsTab = "Pills tabs";
export const JustifyTab = "Justify tabs";
export const MaterialStyleLeftTab = "Material style left tabs";
export const DalbultCaslin = "Dalbult Caslin ";
export const MaterialStyleTab = "Material style tabs";
export const BorderTab = "Border tabs ";
export const Contact = "Contact";
export const Components = "Components";
export const PageTab = "Page";
export const AboutUs = "About us";
export const Blog = "Blog";
export const UxDesigner = "UX designer";
export const IOTDeveloper = "IOT developer";
export const User = "User";
export const Permission = "Permission";
export const Review = "Review";
export const ExampleTextarea = "Example textarea";
export const Home = "Home";
export const Profile = "Profile";
export const ContactUs = "Contact us";

//Ui Kits - Lists
export const Lists = "Lists";
export const DefaultList = "Default lists ";
export const ActiveList = "Active lists ";
export const FlushList = "Flush lists";
export const ContextualClassesHeading = "Contextual classes";
export const HorizontalList = "Horizontal lists";
export const CustomContentList = "Custom content lists";
export const MollyBoake = "Molly Boake";
export const ListsWithCheckboxs = "Lists with checkbox";
export const ListsWithRadio = "Lists with radios";
export const ListsWithNumber = "Lists with numbers";
export const JavaScriptBehaviors = "JavaScript behavior";
export const NumberedBadgeList = "Numbered & badge lists";
export const DisabledList = "Disabled lists ";
export const ScrollableList = "Scrollable lists ";

//Ui Kits - Accordion
export const UiKits = "Ui Kits";
export const Accordion = "Accordion";
export const SimpleAccordions = "Simple accordion";
export const FlushAccordions = "Flush accordion";
export const WhatDoWebDesignersDo = "What do web designers do ?";
export const MultipleCollapseAccordions = "Multiple collapse accordion";
export const ToggleFirstElement = "Toggle first element";
export const ToggleSecondElement = "Toggle second element";
export const ToggleBothElement = "Toggle both element";
export const WithIconsAccordions = "With icons accordion";
export const OutlineAccordions = "Outline accordion ";
export const HorizontalAccordions = "Horizontal accordion";
export const ToggleWidthCollapse = "Toggle width collapse";
export const CollapseAccordions = "Collapse accordion";
export const LinkWithHref = "Link with href";
export const ButtonWithDataBsTarget = "Button with data-bs-target";

//Ui Kits - Dropdown
export const DropdownTitle = "Dropdown";
export const Dropdown = "Dropdown";
export const BasicDropdowns = "Basic dropdown";
export const RoundedDropdowns = "Rounded dropdown";
export const SplitDropdowns = "Split dropdown";
export const Party = "Party";
export const Inputs = "Inputs";
export const HeadingDropdowns = "Heading dropdown";
export const WithInputTypes = "With input type";
export const DarkDropdowns = "Dark dropdown";
export const DarkNight = "Dark Night";
export const UniqueDropdownHeading = "Unique dropdown";
export const DropdownForms = "Dropdown form";
export const Text = "Text";
export const JustifyContent = "Justify contents";
export const TextLeft = "Text-left";
export const Alignment = "Alignments";
export const WarningTop = "Warning top";
export const HelperCards = "Helper card";
export const LearnMore = "Learn More!";
export const WarningCards = "Warning Card";
export const Warning = "Warning!";
export const AlertCards = "Alert Card";
export const Danger = "Danger";
export const DividerDropdowns = "Divider dropdown";
export const DropdownSizings = "Dropdown sizing";
export const LargeButton = "Large button";
export const RememberMe = "Remember me";
export const EmailPlaceholder = "email@example.com";

//Ui Kits- Popover
export const Popover = "Popover";
export const HurryUps = "Hurry Up!";
export const BasicPopovers = "Basic popover";
export const PopoverOnTop = " Popover On Top";
export const PopoverOffsets = "Popover offset ";
export const PopoverDirections = "Popover direction";
export const PopoverOffset50 = "Popover offset 50";
export const PopoverTitle = "Popover Title";

//Ui Kits- Tooltip
export const Tooltip = "Tooltip";
export const TooltipSmall = "tooltip";
export const BasicTooltip = "Basic tooltip";
export const MagicPleaseHoverMe = "It's magic please hover me...";
export const InlineTooltipContent = "Inline tooltip Content";
export const ColoredTooltips = "Colored tooltip ";
export const DirectionsTooltip = "Tooltip  directions";
export const TooltipOnTop = "Tooltip on tops";
export const HTMLElementsWithHoverEffect = "HTML Elements With Hover Effect";
export const NotificationsReceived = "Notifications Received";
export const FilledTooltips = "Filled tooltip";
export const TooltipPrimary = "Tooltip Primary";
export const ThankYouBold = "<b>Thank</b> <em>you</em>";
export const ThankYouItalic = "<i>Thank </i> <u>you</u>";
export const Primary = "Primary";

//Ui Kits- Modal
export const Modal = "Modal";
export const BasicModal = "Basic Modal";
export const Simple = "Simple";
export const ScrollingContent = "Scrolling content";
export const UXDesigners = "UX Designer ";
export const WebDesigns = "Web Design ";
export const TooltipsAndPopovers = "Tooltips and popovers";
export const PopoverModal = "Popover in a modal";
export const TooltipsModal = "Tooltips in a modal";
export const OpenModalForISSO = "Open modal for ISSO";
export const SizesModal = "Sizes Modal";
export const FullScreenModals = "Full Screen Modal";
export const ExtraLargeModals = "Extra large Modal";
export const LargeModals = "Large Modal";
export const SmallModals = "Small Modal";
export const FullScreenModal = "Fullscreen Modal";
export const FullScreenBelowSM = "Full Screen Below sm";
export const FullScreenBelowMD = "Full Screen Below md";
export const FullScreenBelowLG = "Full Screen Below lg";
export const FullScreenBelowXL = "Full Screen Below xl";
export const FullScreenBelowXXL = "Full Screen Below xxl";
export const CenteredModals = "Centered Modal ";
export const VerticallyCentered = "Vertically centered";
export const ToggleBetweenModal = "Toggle Between Modals ";
export const OpenFirstModal = "Open first Modal ";
export const StaticBackdropModals = "Static Backdrop Modal";
export const ISSOCustomModal = "ISSO Custom Modals ";
export const BalanceAmount = "$768,987.90";
export const Close = "Close";
export const SaveChanges = "Save changes";
export const ClickOut = "Click Out";
export const ISSOSignUp = "ISSO SIGN-UP";
export const ISSOLogin = "ISSO Login";
export const StaticBackdropModalBtn = "Static backdrop modal";
export const SomethingWentWrong = "Ohh! Something went wrong!";
export const AlreadyLeaving = "Already leaving??";
export const YesLogOut = "Yes, Log out";
export const ParentToggleTitle =
  "Remove your complete account from your phone or tablet to sign out of the Gmail app.";
export const ConnectNewAccount = "Connect new account";
export const ConnectNewRegisterAccount = "Register";
export const SignUp = "Sign Up";
export const ISSOEmail = "ISSOtheme@gmail.com";
export const EnterYourFirstName = "Enter your first-name";
export const EnterYourLastName = "Enter your last-name";
export const TermsAndCondition =
  "You accept our Terms and Privacy Policy by clicking Submit below.";
export const ExtraLargeModal = "Extra large modal";
export const WebDesign = "Web Design";
export const StartWithYourGoals = "Start with your goals";
export const FullscreenModal = "Fullscreen modal";
export const WebDesigner = "Web Designer";
export const ButtonSmall = "Button Small";
export const TooltipPopoverModal = "Tooltip and Popover Modal";
export const ScrollingModalHeading = "Scrolling Modal";
export const ExploreMore = "Explore More";

//Ui Kits- Progress
export const Progress = "Progress";
export const BasicProgressBars = "Basic progress bars";
export const ProgressBarsStriped = "Progress bars striped";
export const ProgressBarsAnimated = "Progress bars animated";
export const MultipleBars = "Multiple bars";
export const ProgressWithNumberSteps = "Progress with number steps";
export const CustomProgressBars = "Custom progress bars";
export const SmallProgressBars = "Small progress bars";
export const LargeProgressBars = "Large progress bars";
export const GettingStarted = "0% Getting Started ";
export const CustomHeightProgressBars = "Custom height progress bars";

//Ui Kits- Alert
export const Alert = "Alert";
export const LinkColorDarkTheme = "Link color in dark theme";
export const LinkColorLightTheme = "Link color in light theme";
export const OutlineDarkLightAlerts = "Outline dark and light alerts";
export const AlertsWithIconsTextActions = "Alerts with icons and text actions";
export const DismissingDarkAlert = "Dismissing dark alert";
export const DismissingLightAlert = "Dismissing light alert";
export const LiveAlerts = "Live alert";
export const ShowLiveAlert = "Show live alert";
export const LeftBorderAlerts = "Left border alert";
export const AdditionalContents = "Additional content";
export const PleaseCheckYourNotifications = "Please! Check your notifications";
export const Check = "Check";
export const Grid = "Grid";

// Ui-kits
export const HelperClasses = "Helper Classes";
export const StylerBorders = "Styler in Borders";
export const CustomBorderRadiusClass = "Custom border-radius class";
export const BorderColor = "Border color";
export const BorderWidth = "Border-width";
export const BackgroundColors = "Background colors";
export const TextColors = "Text colors";
export const AdditiveBorder = "Additive Border";
export const SubtractiveBorder = "Subtractive Border";
export const AdditiveRadiusHeading = "Additive Radius";
export const DarkBackground = "Dark Background";
export const LightBackground = "Light Background";
export const BordersAndDisplays = "Borders and displays";
export const ExtendedBackgroundColor = "Extended Background Color";
export const Padding = "Padding";
export const PaddingLeft = "Padding Left";
export const MarginLeft = "Margin Left";
export const GridOptions = "Grid options";
export const GridBehavior = "Grid behavior";
export const HorizontalAllTimes = "Horizontal at all times";
export const CollapsedBreakpoints =
  "Collapsed to start, horizontal above breakpoints";
export const MaxContainerWidth = "Max container width";
export const ClassPrefix = "Class prefix";
export const Class = " Class";
export const ValueOfClass = "Value of the class";
export const VerticalAlignmentClass = ".align-items-*";
export const VerticalAlignmentValueClass =
  " start / center / end / baseline / stretch";
export const HorizontalAlignment = "Horizontal Alignment";
export const HorizontalAlignmentValueClass =
  "start / center / end / around / between";
export const HorizontalAlignmentClass = ".justify-content-*";
export const Nesting = "Nesting";
export const Order = "Order";
export const OrderClass = ".order-*";
export const OrderValueClass = " first / 2 / 3 / 4 / 5 / last";
export const Offset = "Offset";
export const OffsetClass = ".offset-*";
export const OffsetValueClass = " 1 / 2 / ... / 11 / 12";
export const Messages = "Messages";
export const TotalSell = "Total Sells";
export const OrdersValue = "Orders Value";
export const DailyOrders = "Daily Orders";
export const DailyRevenue = "Daily Revenue";
export const SalesOverviews = "Sales Overview";
export const FontSizes = "Font Sizes";
export const RecentCustomer = "Recent Customers";
export const RevenueByCategorys = "Revenue By Category";
export const UserContinent = "User by Continent";
export const AllOverContriesSales = "All Over Contries's Sale";
export const Color = "Color";
export const ImagesSizes = "Images Sizes";
export const FontStyle = "Font-Style";
export const SpecialDiscount = "Special Discount";
export const Days = "Days";
export const Hours = "Hours";
export const Margin = "Margin";
export const Min = "Min";
export const Sec = "Sec";
export const Topsellermonth = "Top seller of the month";
export const RecentOrder = "Recent Orders";
export const DealDayFrom = "Deal of the Day From ";
export const SelectSize = "Select size";
export const OnlyOneSidePadding = "Only one side padding";
export const OnlyOneSideMargin = "Only one side margin";

// Ui-kits
export const Typography = "Typography";
export const Headings = "Headings";
export const Code = "Code";
export const FontSize = "Font Size";
export const Heading = "Heading";
export const ColoredHeadings = "Colored Headings";
export const VerticalAlignment = "Vertical Alignment";
export const GridForColumn = "Grid For Column";
export const FontWeight = "Font Weight";
export const FontWeightTitle = "You can set bolder font weight Heading";
export const ListingTypography = "Listing Typography";
export const UnOrderList = "Unorder List";
export const OrderLists = "Order Lists";
export const DescriptionLists = "Description Lists";
export const DisplayHeadings = "Display Headings";
export const InlineTextElements = "Inline Text Elements";
export const TextColor = "Text Color";
export const Blockquote = "Block quotes";
export const Avatar = "Avatars";
export const Sizes = "Sizes";
export const StatusIndicator = "Status Indicator";
export const Shapes = "Shapes";
export const Ratio = "Ratio";
export const Grouping = "Grouping";
export const TagAndPills = "Tag & Pills";
export const BadgesContextualVariations = "Badges Contextual Variations";
export const PillsContextualVariations = "Pills Contextual Variations";
export const NumberOfBadge = "Number of Badge ";
export const NumberOfPillsTags = "Number of Pills Tags";
export const BadgeTagsWithIcons = "Badge Tags With Icons";
export const RoundedPillsWithIcons = "Rounded Pills With Icons";
export const BadgeHeadingsExample = "Badge Headings Example";
export const BadgesAsPartButtons = "Badges As Part Buttons";

// Page Layout
export const SampleCards = "Sample Card";
export const HideMenuOnScroll = "Hide Menu On Scroll";
export const PageLayout = "Page Layout";

// Widgets
export const Widgets = "Widgets";
export const General = "General";
export const Chart = "Chart";
export const MonthlyHistoryHeading = "Monthly History";
export const SkillStatusHeading = "Skill Status";
export const OrdersStatusHeading = "Order Status";
export const LiveProduct = "Live Products";
export const TurnOver = "Turn over";
export const CryptocurrencyPrice = "Cryptocurrency Prices";
export const StockMarkets = "Stock Market";
export const Finances = "Finance";
export const MonthlySale = "Monthly Sales";
export const CryptoAnnotation = "Crypto Annotations";
export const OrdersStatus = "Order Status";
export const Uses = "Uses";
export const UserVisitsByDay = "User Visits by Day";

//Switch
export const FormsWidgets = "Forms Widgets";
export const Switch = "Switch";
export const CustomSwitchHeading = "Custom switch";
export const IconsSwitchHeading = "Icons switch";
export const UncheckedSwitchHeading = "Unchecked switch";
export const BordersWithIcon = "Borders with icons";
export const DisabledOutlineSwitchHeading = "Disabled outline switch";
export const VariationOfSwitchHeading = "Variation of switches";
export const SwitchSizingHeading = "Switch sizing";
export const SwitchWithIcon = "Switch with Icons";

//Typeahead
export const Typeahead = "Typeahead";
export const BasicTypeaheadHeading = "Basic typeahead";
export const StatesOfUSA = "States of USA";
export const PreFetchHeading = "Prefetch";
export const BloodHoundHeading = "Bloodhound (Suggestion Engine)";
export const RemotelyAheadHeading = "Remote typeahead";
export const CustomTemplate = "Custom templates";
export const MultipleSectionWithHeader = "Multiple sections with headers";
export const ScrollableDropDownMenuHeading = "Scrollable Dropdown Menu";
export const RTLSupportHeading = "RTL Support";

//Clipboard
export const Clipboard = "Clipboard";
export const ClipboardTextInput = "Clipboard on text input";
export const CutAndCopyText = "Cut/copy from text input";
export const CutAndCopyTextPlaceholder = "type some text to copy / cut";
export const Copy = "Copy";
export const Cut = "Cut";
export const ClipboardOnTextAreas = "Clipboard on textarea";
export const CutCopyFromTextarea = "Cut/copy from textarea";
export const ClipboardOnParagraphs = "Clipboard on paragraph";
export const CopyFromParagraph = "Copy from paragraph";
export const CopyPortionFromParagraphs = "Copy portion from paragraph";
export const ClipBoardCopy = "Copy Portion From Paragraph";
export const HighlightText = "Web design is the process of creating websites ";
export const CopyHighLightedText = " Copy highlighted text";

//From Layout - wizard 1
export const FormLayout = "Form Layout";
export const FormWizard = "Form Wizard";
export const NumberingWizardHeading = "Numbering wizard";
export const ConfirmPassword = "Confirm Password";
export const AgreeToTermsAndConditions = "Agree to terms and conditions";
export const PlaceHolderName = "Placeholder Name";
export const CheckMeOut = "Check me out";
export const Expiration = "Expiration(MM/YY)";
export const CVVNumber = "CVV Number";
export const UploadDocumentation = "Upload Documentation";
export const AboveInformationCorrect = "All the above information is correct";
export const LinkedIn = "LinkedIn";
export const Github = "Git hub";
export const selectState = "Select State";
export const selectStatePlace = "Select state";
export const GiveFeedback = "Give Feedback";
export const Congratulations = "Congratulations ";
export const StudentValidationFormHeading = "Student validation form";
export const Name = "Name";
export const AddProfile = "Add Profile";
export const PortfolioURL = " Portfolio URL";
export const ProjectDescription = " Project Description";
export const SocialLinks = " Social Links ";
export const Twitter = " Twitter";
export const VerticalValidationWizardHeading = "Vertical validation wizard";
export const selectPaymentMethod = "Select your payment method";
export const NetBanking = " NET BANKING";
export const SelectYourBank = " SELECT YOUR BANK";
export const AgreeConditions = "Agree to terms and conditions";
export const Finish = "Finish";
export const ShippingFormHeading = "Shipping form";
export const BillingInformation = "Billing Information ";
export const FillFollowingInformation = "Fill up the following information ";
export const ShippingInformation = "Shipping Information";
export const SavedAddress = "Saved Address";
export const Information = "Information";
export const ShippingMethod = "Shipping Method";
export const ProceedNext = "Proceed to Next";
export const PaymentInformation = "Payment Information";
export const CreditCard = "Credit Card";
export const CashOnDeliveryHeading = "Cash On Delivery";
export const OrderConfirmed = "Thank you! Your order is confirmed.";
export const OrderID = "Order ID";
export const CurrentCartHeading = "Current Cart";
export const EnterYourName = "Enter your name";
export const EnterPassword = "Enter password";
export const EnterConfirmPassword = "Enter confirm password";
export const PlaceholderName = "Placeholder name";
export const ExpiryPlaceholder = "xx/xx";
export const LinkedInPlaceholder = "https://linkedIn.com/hoho";
export const GitHubPlaceholder = "https://github.com/boho";
export const TwitterPlaceholder = "https://twitter.com/boho";
export const AllTheAboveInformationIsCorrect =
  "All the above information is correct";
export const Address = "Address";
export const RememberMeForNextTime = "Remember me for next time";
export const OtherNotes = "Other notes";
export const EnterYourQueries = "Enter your queries...";
export const ProceedToNext = "Proceed to next";
export const ISSOMail = "ISSO@example.com";
export const AadharPlaceholder = "xxxx xxxx xxxx xxxx";
export const ZipCode = "Zip Code";
export const ContactNumber = "Contact Number";
export const EnterFirstName = "First name";
export const EnterLastName = "Last name";
export const EnterNumber = "Enter number";
export const Country = "Country";
export const Product = "Product";
export const ProductDetails = "Product Details";
export const Price = "Price";
export const Previous = "Previous";
export const Next = "Next";
export const CardHolder = "Card Holder";
export const EnterCardHolderName = "Enter card holder name";
export const CVV = "CVV";
export const Email = "Email";
export const FirstName = "First Name";
export const Password = "Password";
export const CardNumber = "Card Number";
export const EnterState = "Enter state";
export const State = "State";
export const LastName = "Last Name";

export const Submit = "Submit";
export const Paypal = "Paypal";
export const Question = "Question";
export const Answer = "Answer";
export const CatName = "Category";

//From Layout - wizard 2
export const StepFormWizard = "Step Form Wizard";
export const Continue = "Continue";
export const AadharNumber = "Aadhar Number";
export const PAN = "PAN";
export const ChooseBanks = "Choose from these popular banks";
export const DescribeYourIssue = "If no, could you please describe?";
export const SuccessfullyCompleted = "Successfully Completed";
export const AccountType = "Select the type of account";
export const AccountName = "Account Name";
export const SelectTeamWith = "Select team size with";
export const ContactDetails = "Contact details";
export const OrganizationName = "Organization Name";
export const JoinOrganizationType = "Join organization type";
export const OrganizationDescription = "Organization description";
export const CardHolderPlaceholder = "Enter card holder name";
export const PanPlaceholder = "xxxxxxxxxx";
export const InqMail = "org@support.com";
export const ShareYourProblemsAndAnotherIssues =
  "Share your problems and another issues";
export const CustomHorizontalWizardHeading = "Custom horizontal wizard";
export const BusinessVerticalWizardHeading = "Business vertical wizard";
export const BusinessHorizontalWizardHeading = "Business horizontal wizard";
export const CustomVerticalWizardHeading = "Custom vertical wizard";
export const GekkoCo = "Gekko Co";
export const Projects = "Projects";

//Two factor
export const TwoFactor = "Two Factor";
export const TwoFactorAuthenticationHeading = "Two-factor authentication";
export const FactorAuthentication = "2 factor authentication";
export const VerificationCodeHeading = "Verification code";
export const OtpCodeSent = "We've sent a verification code to";
export const OtpCode = "Your OTP Code here:";
export const Verify = "Verify";
export const Call = " Call";
export const ModalTitle = "Modal title";
export const ScanQRCode = "Scan QR code";
export const EmailVerificationHeading = "Email verification";
export const EnterQRCode = "Enter QR Code";
export const PleaseEnterTheCodeHere = "Please enter the code here";
export const Resend = "Resend";
export const Cancel = "Cancel";

//Reactstrap Table - Basic Table
export const ReactstrapTable = "Reactstrap Table";
export const ReactstrapBasicTable = "Reactstrap Basic Tables";
export const BasicTableWithBorderBottomColor =
  "Basic Table With Border Bottom Color";
export const InverseTables = "Inverse Table";
export const HoverableRowsWithHorizontalBorders =
  "Hoverable Rows With Horizontal Border";
export const InverseTablWithPrimaryBackgrounds =
  "Inverse Table with Primary background";
export const CaptionTableTitle = "Caption";
export const Discription = "Discription";
export const UserList = "List of Users";
export const ThirdpartyUserList = "List of Third Party Users";
export const CatUserList = "List of Category Users";
export const TempList = "List of Certificates/Badges";
export const CerBadges = "Certificates & Badges";
export const FAQsList = "List of FAQs";
export const RoleList = "List of Roles";
export const PlanList = "List of plans";
export const BadgeLists = "List of Badges";
export const CustomList = "Form Builder List";
export const eventComList = "Event & Compitition";
export const workshopSemiList = "Workshop";
export const interactiveZoneList = "Interactive Zone";
export const CategoryList = "List of category";
export const Category = "Category";
export const Template = "List of Certificates/Badges";
export const TableHeadOption = "Table head options";
export const StripedRowWithInverseTables = "Striped Row with Inverse Table";
export const BreckpointSpecifics = "Breckpoint Specific";
export const ResponsiveLightBackgroundTitle =
  "Responsive Tables With Light Background";
export const SizingTable = "Sizing Tables";
export const CustomColorHoverStrippedTitle =
  "Custom Table Color With Hover And Stripped";
export const DashedBorderTitle = "Dashed Border";

//Plan Module
export const PlanName = "Plan Name";
export const PlanDes = "Plan Description";
export const Deltxtplan = "This Plan will be deleted permanetly.";
export const NoBenifits = "No Benifits available";
export const Benefits = "Benefits";
export const Getstarted = "Get Started";
export const Addplan = "Add Plan";
export const Editplan = "Edit Plan";
export const plsPlaname = "Enter your plan name";
export const plsBenefits = "Enter your Benefits";
export const plsPrice = "Enter Plan Price";
export const plsDuration = "Plan Duration";

// Promotion
export const ProList = "Promotion List";
export const AddPromotion = "Add Promotion";
export const Enterpercentage = "Percentage";
export const Enteramount = "Amount";
export const plsProDes = "Promotion description";
export const plsQuantity = "Quantity";
export const ProCode = "Promotion Code";
export const useSameCode = "Use Same Promo Code";
export const plsprocode = "Promotion code";
export const ExpairyDate = "Expiry Date";
export const plsSelExpairyDate = "Select expiry date";
export const PromotionDeletetxt = "This Promotion will be deleted permanently.";
export const selectCategory = "Select Category";
export const selectLevel = "Select Level";
export const selectExpdate = "Select Expiry date";
export const selectCredate = "Select Created date";
export const selectPromoCode = "Select Promo Code";

// Waiting List
export const waitinglistworkshop = "Workshop Waiting List";
export const waitinglistSeminar = "Seminar Waiting List";
export const waitinglistEvent = "Event & Compitition Waiting List";
export const waitinglistCompetitions = "Competitions Waiting List";

//Reactstrap Table - Table Components
export const TableComponent = "Table Component";
export const UIComponentsTitle = "UI Components";
export const AlertTitle = "Alerts";
export const ProgressbarTitle = "Progressbar";
export const CheckboxTitle = "Checkbox";
export const RadioButtonsTitle = "Radio Buttons";
export const SelectTable = "Select";
export const InputsTable = "Input";
export const BadgesTable = "Badges";
export const TooltipTriggersTitle = "Tooltip Triggers";
export const SwitchTable = "Switch";

//Data Tables - Basic Data Tables
export const BasicDataTables = "Basic DataTables";
export const DataTables = "Data Tables";
export const ZeroConfiguration = "Zero Configuration";
export const SearchTableButton = "Search";
export const StateSavingTitle = "State saving";
export const ScrollVerticalDynamicHeightTitle =
  "Scroll - vertical, dynamic height";

//Data Tables - Advance Init
export const AdvanceInit = "Advance Init";
export const StockResultTitle = `Stock Result`;
export const RowCreateCallBackSpan = "Row Create Callback";
export const APIDataTables = "API DataTables";
export const AddRowsTitle = "Add rows";
export const AddNewRowButton = "Add new row";
export const ChildrenRowsTitle =
  "Child rows (show extra / detailed information)";
export const ApiFullName = "Full Name: ";
export const ExtraInfo = "Extra info ";
export const ApiExtensionNumber = "Extension number: ";
export const ApiExtraInfo = "'And any further details here (images etc)...'";
export const RowsSelectionAndDeletions =
  "Row Selection And Deletion (Single Row)";
export const DeleteSelectDataButton = "Delete Row";
export const CustomFilteringSearch = "Custom filtering - range search";
export const MinimumAge = "Minimum age: ";
export const MaximumAge = "Maximum age:	";
export const DATASourceDataTables = "DATA Source DataTables";
export const HtmlTableTittle = "HTML (DOM) sourced data";
export const AjaxSourcedDataHeading = "Ajax sourced data";
export const JavascriptSourcedDataHeading = "Javascript sourced data";
export const ServerSideProcessingHeading = "Server-side processing";

// Error
export const BackToHomePage = "BACK TO HOME PAGE";
export const EnterYourDetails = "Enter your personal details to create account";
export const CreateYourAccount = "Create your account";
export const YourName = "Your Name";
export const PrivacyPolicy = "Privacy Policy";
export const AgreeWith = "Agree with";
export const SignUpWith = "Or signup with";
export const Unlock = "Unlock";
export const EnterYourPassword = "Enter your Password";
export const ResetYourPassword = "Reset Your Password";
export const EnterYourMobileNumber = "Mobile number";
export const EnterEmail = "Enter your Email";
export const SignUpToAccount = `Sign Up To Account`;
export const EmailPassWord = `Enter your email & password to login`;
export const HavePassport = "Have Passport";
export const Birthday = "Birthday";
export const RetypePassword = "Re-type Password";
export const EnterOTP = "Enter OTP";
export const CreateYourPassword = "Create Your Password";
export const NewPassword = "New Password";
export const MAINTENANCE = `MAINTENANCE`;
export const SignInAccount = "Sign in to account";
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const SignInWith = "Or Sign In With";
export const FacebookHeading = "facebook";
export const TwitterHeading = "twitter";
export const LinkedInHeading = "LinkedIn";
export const DoNotAccount = "Don't have account?";
export const CreateAccount = "Create Account";
export const ContactNo = "Contact No";
export const Age = "Age";
export const Send = "Send";
export const Done = "Done";
export const EmailsPlaceHolder = "Test@gmail.com";
export const YesNo = "Yes/No";
export const ConfirmPasswordPlaceholder = "Enter again";
export const EmailAddress = "Email Address";
export const SignIn = "Sign In";
export const AlreadyHA = "Already have an account?";
export const City = "City";
export const OtpNotReci = "If don't receive OTP?";

// Coming Soon
export const WeAreComingSoon = "WE ARE COMING SOON";

// Sample Page
export const SamplePage = "Sample Page";

// Project
export const Project = "Project";
export const ProjectListHeading = "Project List";
export const CreateNewProjects = "Create New Project";
export const All = "All";
export const Doing = "Doing";
export const Issues = "Issues";
export const Resolved = "Resolved";
export const Comment = "Comment";
export const ProjectCreates = "Project Create";
export const Add = "Add";
export const ProjectTitle = "Project Title";
export const ClientName = "Client name";
export const ProjectRate = "Project Rate";
export const ProgressLevel = "Progress Level";
export const ProjectStatus = "Project Type";
export const ProjectSize = "Project Size";
export const Medium = "Medium";
export const Big = "Big";
export const StartingDate = "Starting date";
export const EnterSomeDetails = "Enter some Details";
export const UploadProjectFile = "Upload project file";
export const Apps = "Apps";
export const FileManagerHeading = "File Manager";
export const FileFilter = "File filter";
export const AddNew = "Add New";
export const AllFiles = "All Files";
export const File = "Files";
export const Storage = "Storage";
export const PricingPlan = "Pricing Plan";
export const Selected = "Selected";
export const TrialVersion = "Trial Version";
export const FREE = "FREE";
export const Premium = "Premium";
export const QuickAccess = "Quick Access";
export const Folder = "Folders";
export const Upload = "Upload";
export const ClientPlaceholder = "Name client or company name";
export const ProjectNamePlaceholder = "Project name *";
export const Hourly = "Hourly";
export const FixPrice = "Fix price";
export const Priority = "Priority";
export const PriorityPlaceholder = "Select Status";
export const Low = "Low";
export const ProjectSizePlaceholder = "Select Issues";
export const High = "High";
export const Urgent = "Urgent";
export const ProjectProgress = "Project Rate";
export const ProjectProgressPlaceholder = "Enter project Progress";
export const EndingDate = "Ending date";
export const Small = "Small";
export const UploadProjectFiles = "Upload project file";
export const ActualFileUpload = " actually uploaded.)";
export const DropFileUploadText =
  "(This is just a demo dropzone. Selected files are ";
export const DropFilesHereOrClickToUpload =
  "Drop files here or click to upload.";

// Email
export const EmailFilter = "email filter";
export const LetterBox = "Letter Box";
export const ComposeEmail = " Compose Email";
export const Sent = "Sent";
export const Starred = "Starred";
export const Draft = "Draft";
export const Trash = "Trash";
export const Work = "Work";
export const Private = "Private";
export const Support = "Support";
export const AddLabelHeading = "Add Label";
export const Promotion = "Promotion";
export const ComposeMessage = "Compose Message";
export const SaveAsDraft = "Save as draft";
export const InterviewMail = "Interview Mail";
export const LabelName = "Label Name";
export const LabelColor = "Label Color";
export const FromHeading = "From";
export const EnterYourMessages = "Enter Your Messages...";
export const Inbox = "Inbox";
export const Subject = "Subject";
export const EmailTitle = "Email Title"

// Users
export const BOD = "BOD";
export const Followers = "Follower";
export const Following = "Following";
export const Like = "Like";
export const AboutMe = "About Me";
export const Website = "Website";
export const MyProfile = "My Profile";
export const Bio = "Bio";
export const EditProfile = "Edit Profile";
export const Company = "Company";
export const UpdateProfile = "Update Profile";
export const AddprojectAndUpload = "Add project And Upload";
export const ProjectName = "Project Name";
export const Status = "Status";
export const Update = "Update";
export const DesignerTitle = "Designer";
export const Date = "Date";
export const Delete = "Delete";
export const Edit = "Edit";
export const Emailaddress = "Email address";
export const Save = "Save";
export const CancelButton = "Cancel";
export const PostalCode = "Postal Code";
export const Username = "Username";
export const Posts = "Posts";
export const Comments = "Comment";
export const Users = "Users";
export const UsersCards = "Users Cards";
export const UsersProfile = "Users Profile";

// To-do
export const ToDoHeading = "To-Do";
export const ToDoFilter = "To Do filter";
export const ToDoList = "To Do List";
export const AddTask = "Add Task";
export const SocialAppHeading = "Social App";
export const AllTask = "All Task";
export const Completed = "Completed";
export const Pending = "Pending";
export const InProcess = "In Process";
export const TrashHeading = "Trash";

// Calender
export const CalenderHeading = "Calender Basic";
export const DraggableEvents = "Draggable Events";
export const RemoveAfterDrop = "remove after drop";
export const CalendarFilter = "calendar filter";
export const CalendarToday = "Today";
export const CalendarMonth = "Month";
export const CalendarWeek = "Week";
export const CalendarDay = "Day";
export const EventType = "Type";
export const EventStartDate = "Start Date";
export const EventDuration = "Duration";
export const EventEndDate = "End Date";
export const EventRegDate = "Registration Closes";
export const EventLocation = "Location";
export const EventParticipantLimit = "Total Seat";
export const EventRegfee = "Registration Fee";
export const EventCreatedBy = "Created By";
export const EventRegisBtn = "Register";
export const EventJoinBtn = "Join";
export const EventPayment = "Payment";
export const EventPay = "Pay";

// Tasks
export const TasksHeading = "Tasks";
export const TaskFilter = "task filter";
export const Views = "Views";
export const Holidays = "Holidays";
export const CreatedByMeHeading = "Created By Me";
export const TodayTasks = "Today's Tasks";
export const DelayedTasks = "Delayed Tasks";
export const UpcomingTasks = "Upcoming Tasks";
export const ThisWeekTask = "This week tasks";
export const AssignedToMe = "Assigned to me";
export const MyTasks = "My tasks";
export const ThisMonthTasks = "This month tasks";
export const NoTaskDueToday = "No task due today.";
export const NoTasksFound = "No tasks found.";
export const NewTask = "New Task";
export const AddNewTask = "Add New Task";
export const TaskTitle = "Task Title";
export const Print = "Print";
export const UserProfiles = "User Profile";
export const Designer = "Designer";
export const Germany = "Germany";
export const UserEdits = "User Edit";
export const UserCards = "User Cards";
export const Business = "Business";
export const CreateTagHeading = "Create Tag";
export const Newsletter = "News letter";
export const Notification = "Notification";
export const Tags = "Tags";
export const Collection = "Collection";
export const Description = "Description";
export const ShortDescription = "Short Description";
export const TagName = "Tag Name";
export const TagColor = "Tag Color";

// Contact
export const Contacts = "Contacts";
export const ContactFilter = "Contact Filter";
export const FollowUp = "Follow Up";
export const Ideas = "Ideas";
export const NewContacts = "New Contacts";
export const AddContacts = "Add Contacts";
export const Mobile = "Mobile Number";
export const Personal = "Personal";
export const History = "History";
export const MobileNo = "Mobile No";
export const PrintViews = "Print Views";
export const Organization = "Organization";
export const Gender = "Gender";
export const ContactHistory = "Contact History";
export const ContactCreated = "Contact Created";
export const Favorites = "Favorites";
export const Important = "Important";
export const EnterCategoryName = "Enter category name";
export const AddCategory = "Add Category";
export const Phone = "Phone";

// Bookmark
export const BookmarkFilter = "Bookmark Filter";
export const Favourites = "Favourites";
export const SharedWithMe = "Shared with me";
export const AddBookmark = "Add Bookmark";
export const EditBookmark = "Edit Bookmark";
export const MyBookmark = " My bookmark";
export const Orgenization = "Organization";
export const NewBookmark = "New Bookmark";
export const WebUrl = "Web Url";
export const Group = "Group";
export const Title = "Title";
export const CreateTag = "Create Tag";
export const NoBookmarksFound = "No Bookmarks Found.";
export const CreatedByMe = "Created By Me";

// SerachResult
export const SearchWebsite = `Search Website`;
export const SearchPages = `Search Pages`;
export const Images = "Images";
export const Videos = "Videos";
export const Maps = "Maps";
export const SearchPixelStrap = `Search result for "Pixelstrap"`;
export const Pixelstrap = "Pixelstrap ..";
export const FavouriteTVShows = "Favourite TV shows";
export const FavouriteMusicBands = "Favourite Music Bands ";
export const NewLikesThisWeek = "New Likes This Week";
export const MutualFriend = "Mutual Friends";
export const ActivityFeed = "Activity Feed";
export const Photo = "Photo";
export const MoreComments = "More Comments";
export const ProfileIntro = "Profile Intro";
export const SocialNetworks = "Social Networks";
export const Facebook = "Facebook";
export const Dribbble = "Dribbble";
export const AddFriend = "Add Friend";
export const LatestPhoto = "Latest Photos";
export const Friends = "Friends";
export const PepoleKnow = "Pepole You May Know";
export const HobbiesAndInterest = "Hobbies and Interests";
export const Hobbies = "Hobbies";
export const FavouriteMusicBandsArtists = "Favourite Music Bands / Artists";
export const FavouriteBooks = "Favourite Books";
export const FavouriteGames = "Favourite Games:";
export const FavouriteMovies = "Favourite Movies";
export const FavouriteWriters = "Favourite Writers:";
export const OtherInterests = "Other Interests:";
export const EducationAndEmploymentHeading = "Education and Employement";
export const TheNewCollegeOfDesign = "The New College of Design";
export const DigitalDesignIntern = "Digital Design Intern";
export const RembrandtInstitute = "Rembrandt Institute";
export const UXUIDesigner = "UI/UX Designer";
export const TheDigitalCollege = "The Digital College";
export const ViewedYourProfile = "Viewed Your Profile";
export const Tools = "Tools";
export const Audios = "Audios";
export const Settings = "Settings";
export const MyPortfolioTitle = "My Portfolio";

// Chat
export const Chat = "Chat";
export const PrivateChat = " Private Chat";
export const SearchHere = "Search here..";
export const ChatsHeading = "Chats";
export const ContactsHeading = " Contacts";
export const RecentChats = "Recent chats";
export const NameAndPhoneNumber = "Name and phone number";
export const TypeMessageHere = "Type Message here..";
export const SendMessages = "Send messages";
export const AddToFavorites = "Add to favorites";
export const ViewDetails = "View Details";
export const GroupChat = "Group Chat";

// Social App
export const SocialApp = "Social App";
export const MyPage = "My Page";
export const Likes = "Likes";
export const View = "View";
export const FavoriteTVShows = "Favorite TV shows";
export const FavoriteMusicBands = "Favorite Music Bands ";
export const PeopleKnow = "People You May Know";
export const PostYourComments = "Post Your comments";

// Pricing
export const Pricing = "Pricing";
export const BecomeMembers = "Become member";
export const BecomeSignUpeMembers = "Sign Up";
export const Purchase = "Purchase";
export const SimplePricingCards = "Simple Pricing Card";

// Wishlist
export const Wishlist = "Wishlist";
export const MoveToCarts = "Move To Carts";

// OrderHistory
export const OrderHistory = "Orders History";
export const NewOrder = "New Orders";
export const ShippedOrders = "Shipped Orders";
export const CancelledOrders = "Cancelled Orders";
export const Datatableorderhistory = "Datatable order history";
export const Processing = "Processing";
export const Shipped = "Shipped";
export const ShippedOrder = "Shipped Orders";
export const CancelledOrder = "Cancelled Orders";
export const Cancelled = "Cancelled";
export const DataTableOrdersHistory = "Datatable Orders History";

// ProductPages
export const ProductPage = "Product Page";
export const WomenPinkshirt = "Women Pink shirt.";
export const ShareIt = "share it";
export const RateNow = "Rate Now";
export const AddToCart = "Add To Cart";
export const BuyNow = "Buy Now";
export const AddToWishList = "Add To WishList";
export const Brand = "Brand";

// Payment Detail
export const PaymentDetails = "Payment Details";
export const NameOnCard = "Name on card";
export const CreditCards = "Credit Card";
export const DebitCards = "Debit Card";
export const FullNameHere = "Full name here";
export const CVC = "CVC";
export const Pincode = "Pincode";
export const EnterMobileNumber = "Enter mobile number";
export const AcHolderName = "AC Holder name";
export const AccountNumber = "Account number";
export const IcfcCode = "ICFC code";
export const OtherDetails = "Other Details";
export const Cod = "COD";
export const Emi = "EMI";
export const NetBankings = "Net Banking";
export const YourTotalBalance = "768,987.90";
export const ProductName = "Product Name";
export const ArticleNumber = "Article Number";
export const Units = "Units";
export const ExpirationDate = "Expiration Date";
export const AddProduct = "Add Product";
export const ProductList = "Product List";
export const ProductForm = "Product Form";
export const TypesOfProductDiscount = "Types of product discount";
export const CategoryName = "Category Name";
export const CreateNewCategoryHeading = "Create New Category";
export const ChooseYourCurrency = "Choose your currency";
export const InitialCost = "Initial cost";
export const SellingPrice = "Selling price";
export const ProductStocks = "Product stocks";
export const DragFilesHere = "Drag files here";
export const ProductTitle = "Product Title";
export const PublishDateTime = "Publish Date & Time";
export const PublishStatus = " Publish Status";
export const AddTag = "Add Tag";
export const LowStock = "Low Stock";
export const StockQuantity = "Stock Quantity";
export const SKU = "SKU";
export const RestockDate = "Restock Date";
export const PreOrder = "Pre-Order";
export const AllowBackOrders = "Allow Backorders";
export const StockAvailability = "Stock Availability";
export const WeightKg = "Weight (kg)";
export const Dimensions = "Dimensions";
export const ShippingClass = "Shipping Class";
export const Length = "Length[l]";
export const Width = "Width[w]";
export const Height = "Height[h]";
export const AdditionalTagTitle = "Additional Tag Title";
export const SpecificTags = "Specific Tags";
export const AdditionalDescription = "Additional Description";

// Product
export const ProductPages = "Product Page";
export const ProductLists = "Product List";
export const ProductListHeading = "Individual Column Searching (Text Inputs)";
export const PaymentDetailsHeading = "Payment Details";
export const Creditcards = "Credit Card";
export const Debitcard = "Debit Card";
export const COD = "COD";
export const EMI = "EMI";
export const NetBankingTitle = "Net Banking";
export const Products = "Product";
export const Filters = "Filters";
export const AddToCartity = "Add to Cart";
export const Categorys = "Categorys";
export const AddToCartHeading = "Add to Cart";
export const CategoryHeading = "Category";
export const Colors = "Colors";
export const Apply = "Apply";
export const TotalPrice = "Total Price";
export const ContinueShopping = "Continue Shopping";
export const CartEmpty = "Cart Empty";
export const Checkouts = "Checkout";
export const BillingDetails = "Billing Details";
export const TownCity = "Town/City";
export const NewProducts = "New Products";
export const Quantity = "Quantity";
export const Checkout = "Checkout";
export const Carts = "Cart";
export const EnterCouponCode = "Enter coupon code";
export const StateCountry = "State/Country";
export const Shipping = "Shipping";
export const CheckPayments = "Check Payments";
export const CashOnDelivery = "Cash On Delivery";
export const PlaceOrder = "Place Order";
export const Subtotal = "Subtotal";
export const Total = "Total";

// Invoices
export const BilledTo = "Billed To";
export const InvoiceDate = "Invoice Date";
export const InvoiceNumber = "Invoice Number";
export const AmountDus = "Amount Dus (USD)";
export const Rate = "Rate";
export const Qty = "Qty";
export const LineTotal = "Line Total";
export const AuthorizedSign = "Authorized Sign";
export const PrintInvoice = "Print Invoice";
export const Download = "Download";
export const InvoiceNo = "Invoice No.";
export const PaymentStatus = "Payment Status";
export const TotalAmount = "Total Amount";
export const BillingAddress = "BILLING ADDRESS";
export const ShippingAddress = "SHIPPING ADDRESS";
export const Unit = "Unit";
export const Vat = "Vat %";
export const ShippingRate = "ShippingRate";
export const Invoice = "Invoice";
export const InvoiceHeading = "INVOICE";
export const ClientDetails = "CLIENT DETAILS";
export const BankTransfer = "BANK TRANSFER";
export const BankAccount = "Bank Account";
export const AllTaxesIncluded = "All Taxes included";
export const InvoiceTo = "Invoice to";
export const AccountNo = "Account No";
export const DueAmount = "Due Amount";
export const Amount = "Amount";
export const UnitePrice = "Unite Price";
export const PaymentTeams = "Payment Teams";
export const Tax = "Tax";
export const Discount = "Discount";
export const TotalDue = "Total Due";
export const No = "No.";
export const VATTax = "VAT / Tax 15%";
export const ISSO = "ISSO";
export const ItemDescription = "Item Description";
export const SubTotal = "Sub-total";
export const InvoiceOne = "Invoice 1";
export const InvoiceTwo = "Invoice 2";
export const InvoiceThree = "Invoice 3";
export const InvoiceFour = "Invoice 4";
export const InvoiceFive = "Invoice 5";
export const InvoiceSix = "Invoice 6";
//Charts - Apex charts
export const Charts = "Charts";
export const ApexCharts = "Apex charts";
export const BasicAreaCharts = "Basic Area Chart ";
export const AreaSpalineCharts = "Area Spaline Chart";
export const BarCharts = "Bar chart";
export const ColumnCharts = "Column Chart ";
export const BubbleCharts = "3d Bubble Chart";
export const SteplineCharts = "Stepline Chart ";
export const ChartColumn = "Column Chart";
export const PieCharts = "Pie Chart ";
export const DonutChart = "Donut Chart";
export const MixedCharts = "Mixed Chart";
export const CandlestickCharts = "Candlestick Chart ";
export const RadarCharts = "Radar Chart";
export const RadialBarCharts = "Radial Bar Chart";

//Charts - Google charts
export const GoogleCharts = "Google charts";
export const AreaChartOne = "Area Chart 1";
export const ColumnChartOne = "Column Chart 1";
export const AreaChartTwo = "Area Chart 2";
export const ColumnChartTwo = "Column Chart 2";
export const GanttCharts = "Gantt Chart";
export const LineCharts = "Line Chart";
export const ComboCharts = "Combo Chart";
export const BarChartTwo = "bar-chart2";
export const WordTreeChart = "word tree";
export const PieChartOne = "Pie Chart 1";
export const PieChartTwo = "Pie Chart 2";
export const PieChartThree = "Pie Chart 3";
export const PieChartFour = "Pie Chart 4";

//Charts - ChartJS charts
export const ChartJSCharts = "ChartJS charts";
export const ChartJSBarChart = "Bar Chart";
export const ChatJSLineGraph = "Line Graph";
export const ChatJSRadarGraph = "Radar Graph";
export const ChatJSLineChart = "Line Chart";
export const ChatJSPolarChart = "Polar Chart";

//Role
export const role = "Role";
export const roleID = "Role ID";
export const roleName = "Role Name";
export const selectRoles = "Select Roles";
export const addRole = "Add Role";
export const editRole = "Edit Role";
export const addroleuser = "Add Role User";
export const editRoleuser = "Edit Role User";
export const addthirdpartyuser = "Add Third Party User";
export const editthirdpartyuser = "Edit Third Party User";
export const downloadUserData = "Download User Data";
export const roleUserList = "List of Role Users";
export const activeUser = "ActiveUser";
export const inActiveUser = "InActiveUser";
export const permission = "Permission";
export const editPermissions = "Edit Permission";
export const roleDeletetxt = "This Role will be deleted permanently.";
export const roleValidation = "Role is required";
export const permiossionValidation = "At least one permission is required";
export const firstNameValidation = "First name is required";
export const MiddleNameValidation = "Middle name is required";
export const lastNameValidation = "Last name is required";
export const invalidEmail = "Invalid email ";
export const emailValidation = "Email is required";
export const authorEmailValidation = "Author email is required";
export const isValidEmail = "is-valid-email";
export const existAccount = "An account with this email already exists.";
export const emailPlace = "demo@gmail.com";
export const mobileValidation = "Mobile number is required";
export const validMobile = "Please Enter Country code and Mobile Number";
export const requiredMobile =
  "Mobile Number must be a valid number with country code";
export const invalidMobile = "Invalid mobile number";
export const rolesValidation = "Role is required";
export const mobilePlace = "(897)-856-7845";
export const passvalidation = "Password is required";
export const passWordvalidation =
  "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, and one digit";
export const countryValidation = "Country is required";
export const stateValidation = "State is required";
export const cityValidation = "City is required";
export const zipCodeLength = "Please enter a valid Zip Code";
export const enterZip = "Zip code";
export const zipCodeValidation = "Zip code is required";
export const selectCountry = "Select Country";
export const selectCountryPlace = "Select country";
export const selectCity = "Select City";
export const selectStartDateEndDate = "Select Start Date End Date";
export const selectActiveInactiveDate = "Select Active/Inactive Date";
export const noCountriesFound = "No Countries Found";
export const userID = "User ID";
export const uniqueID = "Unique ID";
export const joinDate = "Join Date";
export const activeInactiveDate = "Active/Inactive Date";
export const listofThirdPartyUsers = "List of Third Party Users";
export const listofRoleUsers = "List of Role Users";
export const noDataFound = "No Data Found";
export const active = "Active";
export const deactivated = "Deactivated";
export const clear = "Clear";

// Category Module
export const CatList = "Category";
export const Deletetxtcat = "This category will be deleted permanently.";
export const Deletetxtsem = "This seminar archive will be deleted permanently.";
export const Categoryname = "Category Name";
export const CategoryImage = "Category Image";
export const GenerateContents = "Generate Contents";
export const LevelID = "Level ID";
export const LevelName = "Level Name";
export const PromoCode = "Promo Code";
export const Certificate = "Certificate";
export const CategoryID = "Category ID";
export const AddLevel = "Add Level";

//cms Schoole Module
export const DeletetxtSch = "This School will be deleted permanently.";

// Level Module
export const ListLevel = "List Of Level";
export const LevelDeletetxt = "This Level will be deleted permanently.";
export const Level = "Level";
export const noLevelcategory = "No levels in this category";

// User Module
export const deltxt1 = "Are you sure..?";
export const Adduser = "Add User";

export const Ndf = "No data Found";
export const Deltxt2 = "This User will be deleted permanetly.";

//Seminar Module
export const AddSeminar = "Add Seminar";
export const SemTitle = "Seminar Name";
export const SeminarRegisteredUserList = "Seminar Registerd User List";
export const SeminarCanTxt = "This Seminar will be cancel permanetly.";
export const SeminarDelTxt = "This Seminar will be deleted permanetly.";
export const seminarJoinTxt = "You want to join this seminar.";
export const seminarRegTxt = "You want to register of this seminar.";

// Certificate Module
export const cerType = "Certificate Type";
export const EventName = "Event Name";
export const SelectDate = "Select Date";
export const SelectDateFormat = "Select Date Format";

// FAQs Module
export const AddFAQs = "Add FAQs";
export const Deltxtfaqs = "This FAQs will be deleted permanetly.";

// Event Module
export const AddEvent = "Add Event";
export const Eventname = "Event & Competition Name";
export const EveType = "Event & Compititon Type";
export const SeminarType = "Seminar Type";
export const StartDate = "Event Start Date & Time";
export const EventTimezone = "Event Timezone";
export const EndDate = "Event End Date";
export const RegDeadline = "Registration DeadLine";
export const Capacity = "Participant Limit";
export const thirdPartycate = "Third-party Registration Category";
export const Zoom = "Zoom";
export const UserType = "User Type Inclusion";
export const Uploadimg = "Upload Image";
export const Offline = "Offline Address";
export const Link = "Link";
export const RegFee = "Registration Fee";
export const EventRegistedUserList = "Event Registered UserList";
export const EventDelTxt =
  "This Event & Compitition will be deleted permanetly.";
export const joinTxt = "You want to join this event.";
export const eveRegTxt = "You want to register of this event.";
export const eveAlert =
  "You can no longer join the event. It is starting soon.";

export const Join = "Join";
export const StartEvent = "Start Event";
export const StartSeminar = "Start Seminar";
export const EventCancelTxt =
  "This Event & Compitition will be cancel permanetly.";
export const EventApprove = "Are you sure you want to approve this";
export const EventReject = "Are you sure you want to Reject this";
export const UpcomingEve = "Upcoming Event";
export const UpcomingEventss = "Upcoming Events";
export const EventInfo = "Event Info";

// Sports Type
export const SportsType = "Sports Type";
export const StypeList = "Sports Type";
export const SportTypeName = "Sport Type Title";
export const DeletetxtSport = "This Sport Type will be deleted permanently.";

// Competitions
export const Competname = "Competition Name";
export const CompetType = "Competition Type";

// Certificate Approval
export const Approve = "Approve";
export const Approvals = "Approval";
export const Reject = "Reject";
export const OnHold = "On Hold";
export const onholdTxt = "This Certificate will be On Hold.";
export const approveTxt = "This Certificate will be Approve.";
export const rejectTxt = "This Certificate will be Reject.";
export const Assign = "Assign";
export const Preview = "Preview";
export const assignCertificate = "Do you want to assign this certificate to";
export const assignBadges = "Do you want to assign this badge to";

// Prefix Management
export const PrefixMan = "Prefix Management";
export const ReceiptNo = "Receipt No.";
export const ReceiptNoPlace = "Receipt no.";

//Validations
export const selectLevelValidation = "At least one Level must be selected";
export const levelValidation = "Level are required";
export const startDateValidation = "Start date is required";
export const endDateValidation = "End date is required";
export const dateValidation = "End date can't be before start date";
export const titleValidation = "Title is required";
export const selectBlogtypeValidation = "At least one blog type is required";
export const blogLetterVlaidation = "At least one blog is required";
export const tagsValidation = "At least one tag is required";
export const blogtypeValidation = "Blog type is required";
export const shortdescriptionValidation = "Description is required";
export const blogTitleValidation = "Title is required";
export const authorValidation = "Author name is required";
export const blogContentValidation = "Blog content is required";
export const newsContentValidation = "News content is required";
export const contentLentValidation = "Minimum 200 characters required";
export const oneImageValidation = "At least one image is required";
export const oneBlogImageValidation = "At least one blog image is required";
export const oneNewsImageValidation = "At least one news image is required";
export const oneFilevalidation = "At least one File is required";
export const workShopImageValid = "WorkShop Images is required";
export const workShopMaterialValidation = "WorkShop Materials is required";
export const workShopTypeValidation = "Workshop Type is required";
export const workPlatForm = "WorkShop plateform select is required.";
export const featuredImageValidation = "Featured image is required";
export const publishDateValidation = "Publish date is required";
export const Resolution = "Image resolution should be between";
export const categotyValidation = "Category name is required";
export const descriptionValidation = "Description is required";
export const InstructoreValidation = "Instructor is required";
export const sdescriptionValidation = "Short Description is required";
export const categoryImageValidation = "Category Image is required";
export const leveltypeValidation = "Level is required";
export const levelNameValidation = "Level name is required";
export const textColorValidation = "Text color is required";
export const backgroundColorValidation = "Background color is required";
export const logoValidation = "Logo is required";
export const yearValidation = "Year is required";
export const imageSize = "Allowed jpg, jpeg, png or webp. Max size of 3MB";
export const fileSize = "File Resolution should not be greater or equal ";
export const inavlidResolution = "Invalid resolution. image must be";
export const imageValidation = "Image is requierd";
export const dropImage = "Drop files here or click to upload.";
export const designationValidation = "Designation is required";
export const schoolValidation = "School Name is required";
export const principleValedation = "Principle name is required";
export const addressValidation = "Address is required";
export const landLineValidation = "Number is required";
export const selectCountryValidation = "Please select the country";
export const selectStateValidation = "Please select the state";
export const selectCityValidation = "Please select the city";
export const schoolImageValidation = "School image is required";
export const countryRequired = "Select at least one country";
export const cityRequired = "Select at least one city";
export const stateRequired = "Select at least one state";
export const selectEamil = "Select at least one email";
export const emailReqiured = "At least one email ID is required";
export const eventTitleValidation = "Event title is required";
export const eventDescriptionValidation = "Event description is required";
export const contactTitleValidation = "Contact title is required";
export const contactDescriptionValidation = "Contact description is required";
export const startTimeValidation = "Start time is required";
export const endTimeValidation = "End time is required";
export const startDayValidation = "Start day is required";
export const endDayValidation = "End day is required";
export const locationValidation = "Location is reqiured";
export const locationTitleValidation = "Location Title is required";
export const countryCodeValidation = "Country Code is required";
export const fBLinkValidation = "Facebook link is required";
export const instaLinkValidation = "Instagram link is required";
export const twitterLinkValidation = "Twitter link is required";
export const youtubeLinkValidation = "Youtube link is required";
export const pagetitleValidation = "Please enter page title";
export const metaDescValidation = "Please enter meta description";
export const uRLSlugValidation = "Please enter URL slug";
export const keyWordValidation = "Please enter keywords.";
export const schemaValidation = "Please enter generated schema.";
export const virtualImageValidation = "Virtual image is required";
export const userValidation = "User is required";
export const sportsTypeValidation = "Sport type is required";
export const rankValidation = "Rank is required";
export const metricValidation = "At least one metric is required";
export const leaderBordTitleValidation = "Leader board title is required";
export const leaderBordDescValidation = "Leader board description is required";
export const eduTitleValidation = "Education title is required";
export const eduDescriptionValidation = "Education description is required";
export const eduSubTitleValidation = "Education sub title is required";
export const countValidation = "Count is required";
export const articaleValidation = "At least one article is required";
export const lettersvalidation = "At least one news letters is required";
export const eventValidation = "Event  is required";
export const categoryValidation = "Category is required";
export const typeValidation = "Type is required";
export const guidelineValidation = "Guidelines title is required";
export const eveCompIMageValidation = "Event & Compitition Image is required";
export const eveCompValidation = "Event & Compitition name is required";
export const eventTypeValidation = "Event & Compitition type is required";
export const userIdValidation = "User Id is required";
export const regDeadlineValidation = "Registration deadline is required";
export const regRequired = "Registration end date must be in the future";
export const eveTimeZone = "Event & Compitition Timezone is required";
export const afterDateValidation =
  "Start date must be after the registration end date";
export const afterTaimeValidation =
  "Registration deadline must be later than the start date and time";
export const semTimeZone = "Seminar Timezone is required";
export const durationVAlidation = "Duration is required";
export const startDAteTime = "Start Date and Time is required";
export const participantvalidation = "Participant is required";
export const participantCountvalidation = "Participant must be more than 0";
export const usertypeValiDation = "At least one User Type is required";
export const audience = "Audience Capacity is required";
export const attendfeevalid = "Attendance fee must be more than 0";
export const attendFee = "Attendance Fee is required";
export const regCategory = "Third party category  is required";
export const seminartype = "Seminar Type is required";
export const platformValid = "Seminar plateform select is required.";
export const speakerNamevalid = "Speaker name is required";
export const seminarname = "Seminar name is required";
export const sportTypevalidation = "Sport Type Title is Required";
export const topicValidation = "Topic Name is Required";
export const topicTYpeValid = " Topic Type is Required";
export const sportsTypeImg = "Sport Type Image is required";
export const regFessvalidation = "Registration fee is required";
export const regFeesRequired = "Registration fee must be more than 0";
export const statusValidation = "Status is required";
export const formtypeValidation = "Form type is required";
export const secTitleValidation = "Section title is required";
export const AddSection = "Add Section";
export const secTitle = "Section Title";
export const secDescValidation = "Section description is required";
export const validSection = "Enter valid section title";
export const ansValidation = "Answer is required";
export const queValidation = "Question is required";
export const shortDescValidation = "Short description is required";
export const longDescValidation = "Long description is required";
export const formValidation = "Enter valid form name";
export const formNameValidation = "Form name is required";
export const clientFeild = "Client details fields is required";
export const fNameValidation = "Enter valid first name";
export const lNameValidation = "Enter valid last name";
export const educationReq = "Education is required";
export const websiteReq="Website is required"
export const validEdu = "Enter valid education";
export const validCountry = "Enter valid country name";
export const validCity = "Enter valid city name";
export const validState = "Enter valid state name";
export const bloodValidation = "Blood Group is required";
export const validEmail = "Enter a valid email";
export const validMobileNo = "Enter valid mobile number";
export const termsValid = "Please agree to terms & conditions";
export const successForm = "form submited successfully";
export const birtdayValidation = "Birthdate is required";
export const genderValidation = "Gender is required";
export const genderValid = "Please Select Gender";
export const benifitValid = "At least one benefit is required";
export const benifitsValidation = "Benefit is required";
export const plansDuration = "Plan Duration is required";
export const priceValidation = "Price is required";
export const planNameValidation = "Plan Name is required";
export const pricacyPolicyvalidation = "Privacy policy are required";
export const amountvalidation = "Amount is required";
export const amountTypevalidation = "Amount type is required";
export const expariyDatevalidation = "Expariy date is required ";
export const maxLimitValidation = "Quantity is required";
export const couponValidation = "Coupon code is required ";
export const currentPasswordValid = "Current password is required";
export const confirmPasswordValid = "Confirm password is required";
export const paasMustMatch = "Passwords must match";
export const passMatch =
  "Must contain 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special case character";
export const newPasswordValid = "New password is required";
export const optValidation = "OTP is required";
export const prifixValidation = "Prefix is required";
export const reciptNoValidation = "Receipt no is required";
export const eveImageValidation = "Event image is required";
export const learderBordTitle = "Enter Leaderboard Title";
export const learderBordDesc = "Enter Leaderboard Description";
export const enterDesc = "Description";
export const enterTitle = "Title";
export const workSemImage = "WorkShop & Seminar images is required";
export const inqDescValidation = "Inquiry description is required";
export const inqTitleValidation = "Inquiry title is required";
export const InquiryValidation = "Inquiry is required";
export const messageValidation = "Message is required";
export const NewsLetter = "News Letter";
export const TwoImageValidation = "At least two image is required";
export const subValidation = "Subject is required";
export const MaxTwo = "Maximum two images are allowed";
export const nameValidation = "Name is required";
export const Linkformat = "Invalid link format";
export const checkValidation = "Please select at least one checkbox";
export const workName = "Workshop Name is required";
export const InstructorName = "Instructor Name is required";
export const StatusReq = "At least one status must be select";
export const StartDay = "Start Day";
export const EndDay = "End Day";
export const StartTime = "Start Time";
export const EndTime = "End Time";
export const AddContact = "Add Contact";
export const AmountZero ="Amount must be greater than zero";
export const  AmountExceeds ="Amount exceeds available balance";
export const  AmountReq="Amount is required";

// WorkShop
export const Wrokshoptit = "WorkShop Name";
export const Instructor = "Instructor";
export const WorkShopMaterials = "WorkShop Materials";
export const WorkShopTimezone = "WorkShop Timezone";
export const WorkType = "WorkShop Type";
export const Duration = "Duration";
export const Date_Time = "Date And Time";
export const DateandTime = "Date & Time";
export const Uploadworkshopimg = "WorkShop Image";
export const Uploadworkshopfile = "Material File";
export const workshopUserList = "Workshop  Registered UserList";
export const WorkShopImage = "WorkShop Image";
export const WorkshopJTxt = "You want to join this WorkShop.";
export const WorkshopRTxt = "You want to registration of this seminar.";
export const workCancleTxt = "This WorkShop will be cancel permanetly.";

// Badges Module
export const AddBadge = "Add Badge";
// Template
export const synctemp = "Sync Templete";
export const assignTem = "You want to assign this template with this category";

// News Module
export const NewsTDelTxt = "This news type will be deleted permanently.";
export const NewsDelTxt = "This news will be deleted permanently.";
export const TypeTitle = "News Type";
export const AddNewsType = "Add News Type";
export const TitleReq = "Title is required";
export const TypeID = "Type ID";
export const NewsAnInsights = "News And Insights";
export const Publish = "Publish";
export const UnPublish = "Unpublish";
export const AddNews = "Add News";
export const NewsID = "News ID";
export const Author = "Author";
export const PubDate = "Publish Date";
export const SelStaEndDate = "Select Created Date";
export const SelPunUnpubDate = "Select Publish Start Date";
export const FiltersText = "Filter";
export const SelNewsType = "Select News Type";
export const SelAuthor = "Select Author";
export const NewsTitleReq = "News title is required";
export const AutReq = "Author name is required";
export const EmailReq = "Email is required";
export const InvalidReq = "Invalid email";
export const ShoDescReq = "Short description is required";
export const BloConReq = "Blog content is required";
export const MinCharReq = "Minimum 200 characters required";
export const PubDateReq = "Publish date is required";
export const FeatuImgReq = "Featured image is required";
export const AtOneImgReq = "At least one image is required";
export const NewstypReq = "News type is required";
export const TagReq = "At least one tag is required";
export const NewsIns = "News Insights";
export const AuthName = "Author Name";
export const AuthEmail = "Author Email";
export const NewsContent = "News Content";
export const FeaturedImg = "Featured Image";
export const NewsImg = "News Images";
export const Tagss = "Tags";
export const SaveDraft = "Save as a Draft";
export const RewriteWiAI = "Rewrite with AI";
export const UserNewss = "User News";
export const News = "News";
export const OneNewReq = "At least one news type is required";
export const OneNewsReq = "At least one news is required";
export const UserBlogss = "User Blogs";

// Community Module
export const TopicTDelTxt = "This Topic Type will be deleted permanetly.";
export const TopicDelTxt = "This Topic will be deleted permanetly.";
export const ComDelTxt = "This Comment will be deleted permanetly.";

// Form Builder Module
export const shortAnswerTitle = "Short answer";
export const longAnswerTitle = "Long answer";
export const singleAnswerTitle = "Single answer";
export const singleCheckboxTitle = "Single checkbox";
export const multipleChoiceTitle = "Multiple choice";
export const dropDownTitle = "Drop-down";
export const yesNoTitle = "Yes or No";
export const descriptionTextTitle = "Description text";
export const fileUpload = "File upload";
export const YESTitle = "YES";
export const NOTitle = "NO";
export const EditSection = "Edit Section";
export const FormDelTxt = "This Form will be deleted permanetly.";
export const AnswerDropDown = [
  {
    title: "Short answer",
    value: "short_answer",
  },
  {
    title: "Long answer",
    value: "long_answer",
  },
  {
    title: "Single answer(Radio Button)",
    value: "single_answer",
  },
  {
    title: "Single checkbox",
    value: "single_checkbox",
  },
  {
    title: "Multiple choice",
    value: "multiple_choice",
  },
  {
    title: "Drop-down",
    value: "drop_down",
  },
  {
    title: "Yes or No",
    value: "yes_no",
  },
  {
    title: "Description text",
    value: "description_text",
  },
  {
    title: "File upload",
    value: "file_upload",
  },
  {
    title: "Numeric",
    value: "numeric",
  },
];

export const MultiAnswers = [
  {
    id: 1,
    value: "New answer 1",
    levels: [],
  },
  {
    id: 2,
    value: "New answer 2",
    levels: [],
  },
];

// Meetings
export const joinMeeting = "Join Meeting";
export const canMeeting = "Cancel Meeting";
export const Meetings = "Meetings";
export const addMeet = "Add Meetings";
export const createDate = "Create Date";
export const MeetDate = "Meeting Date";

// InVoice Templates
export const dummyEmail = "info@issoindia.com";
export const dummyMobile = "70028399709";
export const payReceipt = "Payment Receipt";
export const regFees = "Registration Fees";
export const totalAmount = "Total Amount";
export const planAmount = "Plan Amount";
export const INVOICE = "INVOICE";
export const InNumber = "Invoice no";
export const Billfrom = "Bill from :";
export const addIsso = "202, Business speciality centre, MITCON College Road Balewadi Pune 411005";
export const Billto = "Bill to :";
export const Item = "Item";
export const Terms = "Terms:";
export const GST = "GST";
export const Subtotals = "Subtotal:";
export const Taxs = "Tax:";
export const Paid = "Paid:";
export const TotalFee = "Total:";
export const termsDesc = "Please quote invoice number when  remitting funds.";
export const Dollar = "$";

// My Earnings
export const myEarnings = "My Earnings";
export const stuRef = "Student Referral";
export const stuDesc = "Student Discount";
export const refDisc = "Referrer Discount";
export const myTran = "My Transaction";
export const refDescReq = "Referrer discount is required";
export const stuDescReq = "Student discount is required";

