import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Permission Routes
import { authRoutes } from "./AuthRoutes";

// Custom Components
import Spinner from "../Component/CustSpinner/Customspinner.component";
import SchoolViewMore from "../Pages/AdminPanel/CMSHomepage/AboutIntroduction/SchoolViewMore/SchoolViewMore";
import CustomViewBenefits from "../Pages/AdminPanel/FormBuilder/customViewBenifits";

// Routes
const PrivateRoute = React.lazy(() => import("./PrivateRoute"));
const LayoutRoutes = React.lazy(() => import("./LayoutRoutes"));
const Login = React.lazy(() => import("../Pages/Login/Authentication/Login"));
const ForgotPasswordForm = React.lazy(
  () => import("../Pages/Login/ForgotPassword.component")
);
const ViewCustomForm = React.lazy(
  () => import("../Pages/AdminPanel/FormBuilder/ViewCustomForm")
);
const ChatSection = React.lazy(
  () => import("../Pages/AdminPanel/Inquiry/ChatSection")
);
const Unsubscribed = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/Unsubscribed")
);
const HomePage = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/HomePage")
);
const OurKeyProductdetailpage = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/OurKeyProduct/OurKeyProductdetailPage/OurKeyProductdetailpage"
    )
);
const InvoiceEventTemplate = React.lazy(
  () =>
    import(
      '../Component/Application/CustomInvoiceTemp/InvoiceTemplate'
    )
);
const PayInvoiceTemplate = React.lazy(
  () =>
    import(
      '../Component/Application/CustomPayInvoiceTemp/CustomPayInvoiceTemp'
    )
);

const MemberShipPage = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/MemberShipPage/MemberShipPage")
);
const RedirectToForm = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/MemberShipPage/GetReferral")
);
const PrivacyPolicy = React.lazy(
  () =>
    import("../Pages/AdminPanel/CMSHomepage/WebPrivacyPolicy/WebPrivacyPolicy")
);
const WebTanC = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/WebTanC/WebTanC")
);
const AboutPage = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/AboutUsView/AboutPage")
);
const FAQPage = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/FAQPage/FAQPage")
);
const ContactUs = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/ContactUs/ContactUs")
);
const UpcomingEventsDetails = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/UpcomingEventsDetails/UpcomingEventsDetails"
    )
);
const EducationAndTraining = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/EducationAndTraining/EducationAndTraining"
    )
);
// const WorkshopAndSeminar = React.lazy(
//   () =>
//     import(
//       "../Pages/AdminPanel/CMSHomepage/WorkshopAndSeminar/WorkshopAndSeminar"
//     )
// );
const CertificationView = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/CertificationView")
);
const CalenderPage = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/AnnualCalendar/CalenderPage")
);
const PastEvent = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/PastEvents/PastEvent")
);
const EventDetails = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/PastEvents/EventDetailsView")
);
const SeminarDetailPage = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/SeminarDetailView/SeminarDetailView")
);
const WorkshopDetails = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/WorkAnSeminar/WorkshopDetails")
);
const WorkAnSeminar = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/WorkAnSeminar/WorkAnSeminar")
);
const VirtualChallenges = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/VirtualChallengesView/VirtualChallenges"
    )
);
const CommunityForumView = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/CommunityForumView/CommunityForumView"
    )
);
const NewsAnInsights = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/NewsAnInsightsModule/NewsAnInsights"
    )
);
const BlogNewsDetails = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/LatestBlogNewsDetails/LatestBlogNewsDetails"
    )
);
const LatestNewsView = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/LatestNewsView/LatestNewsView"
    )
);
const NewsAnInsightsDetails = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/NewsAnInsightsModule/NewsAnInsightsDetails/NewsAnInsightsDetails"
    )
);
const NewsBlogs = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/NewsAnInsightsModule/NewsBlogs/NewsBlogs"
    )
);
const EventRegistration = React.lazy(
  () =>
    import(
      "../Pages/AdminPanel/CMSHomepage/Event Registration/EventRegistration"
    )
);
const GameAndQueries = React.lazy(
  () => import("../Pages/AdminPanel/CMSHomepage/Games&Quizzes")
);

interface RouteConfig {
  path: string;
  component: React.ReactNode;
}

const homeRoutes: RouteConfig[] = [
  {
    path: "/login",
    component: (
      <Suspense fallback={<Spinner />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/forgetpassword",
    component: (
      <Suspense fallback={<Spinner />}>
        <ForgotPasswordForm />
      </Suspense>
    ),
  },
  {
    path: "/customform/view/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <ViewCustomForm />
      </Suspense>
    ),
  },
  {
    path: "/customform/view/:id/:referral_code",
    component: (
      <Suspense fallback={<Spinner />}>
        <ViewCustomForm />
      </Suspense>
    ),
  },
  {
    path: "/customform/auth-view",
    component: (
      <Suspense fallback={<Spinner />}>
        <ViewCustomForm />
      </Suspense>
    ),
  },
  {
    path: "/customform/purchase-plan/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <ViewCustomForm />
      </Suspense>
    ),
  },

  // Invoice
  {
    path: "/invoice/:id/:type",
    component: (
      <Suspense fallback={<Spinner />}>
        <InvoiceEventTemplate />
      </Suspense>
    ),
  },
  {
    path: "/pay-invoice/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <PayInvoiceTemplate />
      </Suspense>
    ),
  },
  {
    path: "/unsubscribed",
    component: (
      <Suspense fallback={<Spinner />}>
        <Unsubscribed />
      </Suspense>
    ),
  },
  {
    path: "/home-page",
    component: (
      <Suspense fallback={<Spinner />}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: "/home-page/about-us",
    component: (
      <Suspense fallback={<Spinner />}>
        <AboutPage />
      </Suspense>
    ),
  },
  {
    path: "/home-page/education-and-training",
    component: (
      <Suspense fallback={<Spinner />}>
        <EducationAndTraining />
      </Suspense>
    ),
  },
  // {
  //   path: "/home-page/workshopAndseminar",
  //   component: (
  //     <Suspense fallback={<Spinner />}>
  //       <WorkshopAndSeminar />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/home-page/ourkeyproduct-list",
    component: (
      <Suspense fallback={<Spinner />}>
        <OurKeyProductdetailpage />
      </Suspense>
    ),
  },
  {
    path: "/home-page/membership-page",
    component: (
      <Suspense fallback={<Spinner />}>
        <MemberShipPage />
      </Suspense>
    ),
  },
  {
    path: "/home-page/get-referral/:id/:referral",
    component: (
      <Suspense fallback={<Spinner />}>
        <RedirectToForm />
      </Suspense>
    ),
  },
  {
    path: "/home-page/membership-page/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <MemberShipPage />
      </Suspense>
    ),
  },
  // {
  //   path: "/home-page/faqs",
  //   component: (
  //     <Suspense fallback={<Spinner />}>
  //       <FAQPage />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/home-page/event-registration",
    component: (
      <Suspense fallback={<Spinner />}>
        <EventRegistration />
      </Suspense>
    ),
  },
  {
    path: "/home-page/event-details/CustomForm",
    component: (
      <Suspense fallback={<Spinner />}>
        <CustomViewBenefits/>
      </Suspense>
    ),
  },
  {
    path: "/home-page/faq-page",
    component: (
      <Suspense fallback={<Spinner />}>
        <FAQPage />
      </Suspense>
    ),
  },
  {
    path: "/home-page/privacy-policy",
    component: (
      <Suspense fallback={<Spinner />}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/home-page/contact-us",
    component: (
      <Suspense fallback={<Spinner />}>
        <ContactUs />
      </Suspense>
    ),
  },
  {
    path: "/home-page/popularevents-details",
    component: (
      <Suspense fallback={<Spinner />}>
        <UpcomingEventsDetails />
      </Suspense>
    ),
  },
  {
    path: "/home-page/terms-and-conditions",
    component: (
      <Suspense fallback={<Spinner />}>
        <WebTanC />
      </Suspense>
    ),
  },
  {
    path: "/home-page/certification-programs",
    component: (
      <Suspense fallback={<Spinner />}>
        <CertificationView />
      </Suspense>
    ),
  },
  {
    path: "/home-page/calendar",
    component: (
      <Suspense fallback={<Spinner />}>
        <CalenderPage />
      </Suspense>
    ),
  },
  {
    path: "/home-page/calendar/:type/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <CalenderPage />
      </Suspense>
    ),
  },
  {
    path: "/home-page/past-events",
    component: (
      <Suspense fallback={<Spinner />}>
        <PastEvent />
      </Suspense>
    ),
  },
  {
    path: "/home-page/event-details/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <EventDetails />
      </Suspense>
    ),
  },
  {
    path: "/home-page/seminar-details/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <SeminarDetailPage />
      </Suspense>
    ),
  },
  // {
  //   path: "/home-page/virtual-challenges",
  //   component: (
  //     <Suspense fallback={<Spinner />}>
  //       <VirtualChallenges />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/home-page/community",
    component: (
      <Suspense fallback={<Spinner />}>
        <CommunityForumView />
      </Suspense>
    ),
  },
  {
    path: "/home-page/news-and-insights",
    component: (
      <Suspense fallback={<Spinner />}>
        <NewsAnInsights />
      </Suspense>
    ),
  },
  {
    path: "/home-page/blog-view",
    component: (
      <Suspense fallback={<Spinner />}>
        <BlogNewsDetails />
      </Suspense>
    ),
  },
  {
    path: "/home-page/news-view",
    component: (
      <Suspense fallback={<Spinner />}>
        <LatestNewsView />
      </Suspense>
    ),
  },
  {
    path: "/home-page/news-letter-details/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <NewsAnInsightsDetails />
      </Suspense>
    ),
  },
  {
    path: "/home-page/news-blogs-details/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <NewsBlogs />
      </Suspense>
    ),
  },
  {
    path: "/home-page/workshop-and-seminar",
    component: (
      <Suspense fallback={<Spinner />}>
        <WorkAnSeminar />
      </Suspense>
    ),
  },
  {
    path: "/home-page/workshop-details/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <WorkshopDetails />
      </Suspense>
    ),
  },
  // {
  //   path: "/home-page/game-and-queries",
  //   component: (
  //     <Suspense fallback={<Spinner />}>
  //       <GameAndQueries />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/home-page/school-view",
    component: (
      <Suspense fallback={<Spinner />}>
        <SchoolViewMore />
      </Suspense>
    ),
  },
  {
    path: "/inquiry/a/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <ChatSection isAdmin={true} />
      </Suspense>
    ),
  },
  {
    path: "/inquiry/u/:id",
    component: (
      <Suspense fallback={<Spinner />}>
        <ChatSection isAdmin={false} />
      </Suspense>
    ),
  },
];

const RouterData = () => {
  const login = localStorage.getItem("ISSO");

  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        {login ? (
          <>
            <Route
              path={`${process.env.PUBLIC_URL}` || "/"}
              element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}
            />
          </>
        ) : (
          ""
        )}
        <Route
          path={"/"}
          element={
            <Suspense fallback={<Spinner />}>
              <PrivateRoute />
            </Suspense>
          }
        >
          <Route
            path={`/*`}
            element={
              <Suspense fallback={<Spinner />}>
                <LayoutRoutes />
              </Suspense>
            }
          />
        </Route>

        {authRoutes.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}

        {homeRoutes.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
